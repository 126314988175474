import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {VisiteTechnique} from "../../models/intervention.model";

export interface EditVisiteTechniqueDto {
    technician?: any,
    date?: string;
    duration?: number;
    desiredInstallationDate?: string;
    particularConditions?: string;
    observations?: string;
    cadastre?: {
        commune: string;
        section: string;
        parcelle: string;
        prefixe: string;
        contenance: string;
    },
    exactPosition?: {
        coords: [number, number];
    }
}

export async function editVisiteTechnique(id: string, dto: EditVisiteTechniqueDto = {}) {
    const url = Endpoint.editVisiteTechnique.replace(':id', id);

    const {data} = await interventionHttp.post<VisiteTechnique>(url, dto);

    return data;
}
