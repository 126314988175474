export enum Endpoint {
    searchProducts = '/amdpeasysaleskms/products',
    createThirdParty = '/amdpeasysaleskms/thirdparty',
    proposals = '/proposals',
    proposal = '/proposals/:id',
    proposalAvailableDocuments = '/amdpsales/proposal/available-documents',
    proposalAcceptOrReject = '/proposals/:id/close',
    proposalLines = '/proposals/:id/lines',
    proposalLine = '/proposals/:id/lines/:lineId',
    proposalSetToDraft = '/proposals/:id/settodraft',
    proposalValidate = '/proposals/:id/validate',
    thirdParty = '/thirdparties/:id',
    documentBuildDoc = '/documents/builddoc',
    documentDownload = '/documents/download',
    documentUpload = '/documents/upload',
    foires = '/amdpeasysaleskms/foire',
    users = '/users',
    me = '/users/login/:login',
    userLogin = '/login',
    company = '/multicompany',
    orderValidate = '/orders/:id/validate',
    viewCompanyLogo = '/viewimage.php?modulepart=mycompany&file=logos/thumbs/:filename&entity=:entity',
    statsLastModifiedInterventions = '/amdpsales/lastModifiedInterventions',
    statsLastModifiedProposals = '/amdpsales/lastModifiedProposals',
    statsProposals = '/amdpsales/proposalStats',
    interventions = '/interventions',
    interventionsSearch = '/interventions/search',
    intervention = "/interventions/:id",
    interventionEditReport = '/interventions/:id/edit-report',
    interventionGenerateReport = '/interventions/:id/generate-reports',
    interventionFiles = '/interventions/:id/files',
    interventionUnlinkFiles = '/interventions/:id/unlink-files',
    interventionCountByStatus = '/interventions/countByStatus',
    interventionAttachFiles = '/interventions/:id/attach-files',
    createVisiteTechnique = '/interventions-of-visite-technique/create',
    editVisiteTechnique = '/interventions-of-visite-technique/:id/edit',
    cancelVisiteTechnique = '/interventions-of-visite-technique/:id/cancel',
    cancelIntervention = '/interventions/:id/cancel',
    closeVisiteTechnique = '/interventions-of-visite-technique/:id/close',
    cadastreJob = '/cadastre/job',
    cadastreFillCerfaDp = '/cadastre/cerfa/13703_09',
    installationProcedures = "/installation-procedures",
    agentIsOnTheWayToIntervention = '/interventions-of-rdv-com/:id/on-the-way',
    agentArriveAtTheInterventionPlace = '/interventions-of-rdv-com/:id/arrived',
    agentIsLeavingInterventionPlace = '/interventions-of-rdv-com/:id/departure',
    createRdvCom = '/interventions-of-rdv-com/create',
    closeRdvCom = '/interventions-of-rdv-com/:id/close',
    closePose = '/interventions-of-pose/:id/close',
    editRdvCom = '/interventions-of-rdv-com/:id/edit',
    setInterventionStatus = '/interventions/:id/status/:status',
}

