import create from "zustand";
import {SearchInterventionsPayload} from "../../calls/Interventions/searchInterventions";

interface StoreModel {
    segment: 'todo' | 'all',
    filter: SearchInterventionsPayload;
    pagination: {
        offset: number;
        limit: number;
    }
}

interface StoreActions {
    setSegment(segment: 'todo' | 'all',): void;
    setFilter(filter: SearchInterventionsPayload): void;
    setPagination(pagination: { offset: number, limit: number }): void;
    fetchMore(): void;
}

const defaultPagination = {
    offset: 0,
    limit: 10,
}

export const useInterventionsFilterStore = create<StoreModel & StoreActions>((set) => ({
    segment: 'todo',
    setSegment(segment) {
        set({ segment });
    },
    filter: {},
    setFilter(filter) {
        set({ filter, pagination: defaultPagination });
    },
    pagination: defaultPagination,
    setPagination({ offset, limit }) {
        set({ pagination: { offset, limit } });
    },
    fetchMore() {
        set(state => ({
            ...state.filter,
            pagination: {
                ...state.pagination,
                offset: state.pagination.offset + 1,
            }
        }));
    }
}));
