import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {defineCustomElements} from "@ionic/pwa-elements/loader";
import {frFR, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";

// Create a client
const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <IntlProvider locale={'fr'}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={'fr'}
                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <App />
                </LocalizationProvider>
            </QueryClientProvider>
        </IntlProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
