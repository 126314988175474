import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenuToggle,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React from "react";
import {logOutOutline, logOutSharp} from "ionicons/icons";
import CompanySwitchButton from "../components/Company/CompanySwitchButton";
import {useHistory} from "react-router-dom";
import {useLogout, useUser} from "../components/Auth/auth.store";
import {userToString} from "../models/user.model";

const Account: React.FC = () => {
    const user = useUser();
    const logoutAndRedirect = useLogoutAndRedirect();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>
                        {userToString(user)}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    <CompanySwitchButton />
                </IonList>

                <IonMenuToggle key={"logout"} autoHide={false} style={{ marginTop: 'auto' }}>
                    <IonItem detail={false} button lines="none" onClick={logoutAndRedirect}>
                        <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
                        <IonLabel>{"Déconnexion"}</IonLabel>
                    </IonItem>
                </IonMenuToggle>
            </IonContent>
        </IonPage>
    )
};

export default Account;

function useLogoutAndRedirect() {
    let history = useHistory();
    const logout = useLogout();

    return () => {
        // reset du state
        logout();

        // redirection après logout
        history.push('/Login');
    }
}
