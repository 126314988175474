import {Controller} from "react-hook-form";
import {IonCheckbox, IonItem, IonLabel} from "@ionic/react";
import React from "react";

interface GenericCheckBoxProps {
    label: string;
    name: string;
    slotProps?: {
        checkBox?: {
            slot: 'start' | 'end'
        }
    }
}

export default function GenericCheckBox({ label, name, slotProps = {} }: GenericCheckBoxProps) {
    return (
        <Controller
            name={name}
            render={
                ({ field: { value, onChange, } }) => {
                    return (
                        <IonItem>
                            <IonCheckbox
                                slot={slotProps?.checkBox?.slot || 'start'}
                                checked={value}
                                onIonChange={({ detail: { checked } }) => onChange(checked)}
                            />
                            <IonLabel>{label}</IonLabel>
                        </IonItem>
                    )
                }
            }
        />
    )
}
