export enum DifficulteChantier {
    FAIBLE = '+',
    MOYENNE = '++',
    DIFFICILE = '+++'
}

export enum TypeContruction {
    NEUVE = 'Neuve',
    EXISTANTE = 'Existante',
}

export enum TypeToiture {
    INCLINEE = 'Inclinée',
    PLATE = 'Plate',
}

export enum NatureCouverture {
    ARDOISE = 'Ardoise',
    TUILE_PLATE = 'Tuile plate',
    TUILE_CANAL = 'Tuile canal',
    PLAQUE_FIBRO_CIMENT = 'Plaque fibro ciment',
    PLAQUE_FIBRO_CIMENT_AMIANTEE = 'Plaque fibro ciment Amiantée',
    BAC_ACIER = 'Bac acier',
    BITUME = 'Bitume',
    BITUME_GRAVIER = 'Bitume + Gravier',
    EPDM = 'EPDM',
}

export enum TypeCharpente {
    TRADITIONNELLE = 'Traditionnelle',
    FERMETTE = 'Fermette',
    INDUSTRIELLE = 'Industrielle',
    ACIER = 'Acier',
    BOIS = 'Bois',
    METAL = 'Métal',
    BETON = 'Béton',
}

export interface RapportVisiteTechniqueFormModel {
    informations: {
        dateVisite: string;
        nomSite: string;
        nomContact: string;
        intitulePoste: string;
        email: string;
        tel: string;
        referenceVT: string;
    },
    suiviCommercial: {
        datePremiereVisite: string;
        dateSecondeVisite: string;
        dateTroisiemeVisite: string;
        dateQuatriemeVisite: string;

        niveauConnaissancePV: '0' | '1' | '2';

        difficulte: DifficulteChantier;

        projetLocationToiture: boolean;
        projetLocationTerrain: boolean;
        promesseBailSignee: boolean;
        bailEmphyteotiqueSigne: boolean;
    },
    construction:  { type: TypeContruction.NEUVE, } | { type: TypeContruction.EXISTANTE, anneeConstruction: string; } &
        { etages?: boolean; etageToiture?: string; }
    caracteristiquesBatiment: {
        elementsCouverture: {
            typeToiture: TypeToiture.INCLINEE;
            inclinaison: string;
            natureCouverture: NatureCouverture.ARDOISE |
                NatureCouverture.TUILE_PLATE |
                NatureCouverture.TUILE_CANAL |
                NatureCouverture.PLAQUE_FIBRO_CIMENT |
                NatureCouverture.PLAQUE_FIBRO_CIMENT_AMIANTEE |
                NatureCouverture.BAC_ACIER
        } | {
            typeToiture: TypeToiture.PLATE,
            natureCouverture: NatureCouverture.BITUME |
                NatureCouverture.BITUME_GRAVIER |
                NatureCouverture.BAC_ACIER |
                NatureCouverture.EPDM
        };

        elementsCharpente: {
            type: TypeCharpente;
            localTechniqueEnTerrasse: boolean;
            combles: boolean;
            passageTechnique: boolean;
        }
    },
    croquis: string;
}
