import {cadastreHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface CreateCadastreJobDto {
    commune: string;
    section: string;
    parcelle: string;
    prefixe: string;
    codePostal: string;
}

export async function createCadastreJob(dto: CreateCadastreJobDto) {
    const url = Endpoint.cadastreJob;

    const {data: blob, headers} = await cadastreHttp.post(url, dto, {
        responseType: 'blob'
    });

    const headerLine = (headers as any).get('content-disposition') || '';
    const match = headerLine!.match(/filename\s*=\s*"(.+)"/i);
    const filename = match ? match[1] : 'cadastre.pdf';

    return {
        filename,
        blob,
    };
}
