import React, {useEffect} from "react";
import {IonAccordion, IonAccordionGroup, IonIcon, IonImg, IonItem, IonLabel, IonList, IonText} from "@ionic/react";
import {useController, useWatch} from "react-hook-form";
import GenericTextField from "./Form/Controls/Generic/GenericTextField";
import GenericDatetimePicker from "./Form/Controls/Generic/GenericDatetimePicker";
import GenericRadio from "./Form/Controls/Generic/GenericRadio";
import {DifficulteChantier, NatureCouverture, TypeCharpente, TypeContruction, TypeToiture} from "./Form";
import GenericCheckBox from "./Form/Controls/Generic/GenericCheckBox";
import {useSketchingPadModalStore} from "../Sketching/sketching-pad-modal.store";

const Photovoltaique: React.FC = () => {
    return (
        <>
            <IonAccordionGroup value={"pv"} multiple={true}>
                <IonAccordion value="pv">
                    <IonItem slot="header" color="light">
                        <IonLabel color="tertiary">Informations</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <Informations />
                    </div>
                </IonAccordion>
                <IonAccordion value="pv">
                    <IonItem slot="header" color="light">
                        <IonLabel color="tertiary">Suivi Commercial</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <SuiviCommercial />
                    </div>
                </IonAccordion>
                <IonAccordion value="pv">
                    <IonItem slot="header" color="light">
                        <IonLabel color="tertiary">Construction</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <Construction />
                    </div>
                </IonAccordion>
                <IonAccordion value="pv">
                    <IonItem slot="header" color="light">
                        <IonLabel color="tertiary">Caracterisitiques du batiment</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <CaracteristiquesBatiment />
                    </div>
                </IonAccordion>

                <IonAccordion value="pv">
                    <IonItem slot="header" color="light">
                        <IonLabel color="tertiary">Croquis</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <AddCroquis />
                    </div>
                </IonAccordion>
            </IonAccordionGroup>
        </>
    )
}

function Informations() {
    const prefixer = (name: string) => `informations.${name}`;

    return (
        <>
            <GenericDatetimePicker
                name={prefixer("dateVisite")}
                label={"Date de la visite"}
            />

            <GenericTextField
                label={"Nom du Site"}
                name={prefixer("nomSite")}
            />

            <GenericTextField
                label={"Nom du contact"}
                name={prefixer("nomContact")}
            />

            <GenericTextField
                label={"Intitulé du poste"}
                name={prefixer("intitulePoste")}
            />

            <GenericTextField
                label={"Email"}
                name={prefixer("email")}
                type={'email'}
            />

            <GenericTextField
                label={"Téléphone"}
                name={prefixer("tel")}
            />
        </>
    )
}

function SuiviCommercial() {
    const prefixer = (name: string) => `suiviCommercial.${name}`;

    const niveauConnaissancePVOptions = [
        {
            label: '🙁',
            value: '0'
        },
        {
            label: '😐',
            value: '1'
        },
        {
            label: '🙂',
            value: '2'
        }
    ];

    const difficulteOptions = [
        {
            label: '🌶️',
            value: DifficulteChantier.FAIBLE,
        },
        {
            label: '🌶️🌶️',
            value: DifficulteChantier.MOYENNE,
        },
        {
            label: '🌶️🌶️🌶️',
            value: DifficulteChantier.DIFFICILE
        },
    ]

    return (
        <>
            <IonText style={{ marginBottom: 5 }}>
                <b>Dates visites</b>
            </IonText>

            <GenericDatetimePicker name={prefixer("datePremiereVisite")} label={"Date 1ere Visite"} />
            <GenericDatetimePicker name={prefixer("dateSecondeVisite")} label={"Date 2ème Visite"} />
            <GenericDatetimePicker name={prefixer("dateTroisiemeVisite")} label={"Date 3ème Visite"} />
            <GenericDatetimePicker name={prefixer("dateQuatriemeVisite")} label={"Date 4ème Visite"} />

            <IonList>
                <GenericRadio
                    label={"Niveau de connaissance du PV"}
                    name={prefixer("niveauConnaissancePV")}
                    choices={niveauConnaissancePVOptions}
                />
            </IonList>

            <IonList>
                <GenericRadio
                    label={"Difficulté chantier"}
                    name={prefixer("difficulte")}
                    choices={difficulteOptions}
                />
            </IonList>

            <IonList>
                <GenericCheckBox label={"Projet location de toiture"} name={prefixer("projetLocationToiture")} />
                <GenericCheckBox label={"Projet location de terrain"} name={prefixer("projetLocationTerrain")} />
                <GenericCheckBox label={"Promesse de Bail signée"} name={prefixer("promesseBailSignee")} />
                <GenericCheckBox label={"Bail emphytéotique signé"} name={prefixer("bailEmphyteotiqueSigne")} />
            </IonList>
        </>
    )
}

function Construction() {
    const prefixer = (name: string) => `construction.${name}`;

    const options = [
        {
            label: 'Neuve',
            value: TypeContruction.NEUVE,
        },
        {
            label: 'Existante',
            value: TypeContruction.EXISTANTE,
        },
    ];

    const type = useWatch({ name: prefixer("type"), defaultValue: '' });

    return (
        <>
            <GenericRadio
                label={""}
                name={prefixer("type")}
                choices={options}
            />

            {
                (type === TypeContruction.EXISTANTE) && (
                    <GenericTextField
                        label={"Année de construction"}
                        name={prefixer("anneeConstruction")}
                    />
                )
            }

            <div style={{ padding: '5px' }}>
                <ConstructionEtages />
            </div>
        </>
    )
}

function ConstructionEtages() {
    const prefixer = (name: string) => `construction.${name}`;

    const isEtages = useWatch({ name: prefixer("etages") });

    return (
        <>
            <GenericCheckBox
                name={prefixer("etages")}
                label={"Construction à étages"}
                slotProps={{
                    checkBox: {
                        slot: 'end'
                    }
                }}
            />

            {
                isEtages && (
                    <GenericTextField
                        label={"Etage toiture"}
                        name={prefixer("etageToiture")}
                    />
                )
            }
        </>
    )
}

function CaracteristiquesBatiment() {
    const prefixer = (name: string) => `caracteristiquesBatiment.${name}`;

    const typeToiture = useWatch({ name: prefixer("elementsCouverture.typeToiture") });

    return (
        <>
            <IonText style={{ marginBottom: 5 }}>
                <b>Elements de couverture</b>
            </IonText>

            <IonList>
                <GenericRadio
                    label={"Type de toiture"}
                    name={prefixer("elementsCouverture.typeToiture")}
                    choices={[
                        {
                            label: TypeToiture.INCLINEE,
                            value: TypeToiture.INCLINEE,
                        },
                        {
                            label: TypeToiture.PLATE,
                            value: TypeToiture.PLATE,
                        }
                    ]}
                />

                {
                    (typeToiture === TypeToiture.INCLINEE) && (
                        <GenericTextField
                            label={"Inclinaison"}
                            name={prefixer("elementsCouverture.inclinaison")}
                        />
                    )
                }
            </IonList>

            <IonList>
                <GenericRadio
                    label={"Nature de la couverture"}
                    name={prefixer("elementsCouverture.natureCouverture")}
                    choices={
                        (
                            typeToiture === TypeToiture.INCLINEE ? (
                                [
                                    NatureCouverture.ARDOISE,
                                    NatureCouverture.TUILE_PLATE,
                                    NatureCouverture.TUILE_CANAL,
                                    NatureCouverture.PLAQUE_FIBRO_CIMENT,
                                    NatureCouverture.PLAQUE_FIBRO_CIMENT_AMIANTEE,
                                    NatureCouverture.BAC_ACIER,
                                ]
                            ) : (
                                [
                                    NatureCouverture.BITUME,
                                    NatureCouverture.BITUME_GRAVIER,
                                    NatureCouverture.BAC_ACIER,
                                    NatureCouverture.EPDM,
                                ]
                            )
                        )
                            .map(n => {
                                return ({
                                    label: n,
                                    value: n
                                })
                            })
                    }
                />
            </IonList>

            <IonText style={{ marginBottom: 5 }}>
                <h5>Elements de charpente</h5>
            </IonText>

            <IonList>
                <GenericRadio
                    label={"Type"}
                    name={prefixer("elementsCharpente.type")}
                    choices={
                        [
                            TypeCharpente.TRADITIONNELLE,
                            TypeCharpente.FERMETTE,
                            TypeCharpente.INDUSTRIELLE,
                            TypeCharpente.ACIER,
                            TypeCharpente.BOIS,
                            TypeCharpente.METAL,
                            TypeCharpente.BETON,
                        ].map(n => {
                            return ({
                                label: n,
                                value: n
                            })
                        })
                    }
                />
            </IonList>

            <IonList>
                <GenericCheckBox label={"Local technique en terrasse"} name={prefixer("elementsCharpente.localTechniqueEnTerrasse")} />
                <GenericCheckBox label={"Combles"} name={prefixer("elementsCharpente.combles")} />
                <GenericCheckBox label={"Passage technique"} name={prefixer("elementsCharpente.passageTechnique")} />
            </IonList>
        </>
    )
}

const drawingIcon = '/assets/sketch/icons/drawing.svg'

function AddCroquis() {
    const {
        field: { value: sketch, onChange },
    } = useController({
        name: 'croquis',
        defaultValue: "",
    });

    const { showModal, data, resetData } = useSketchingPadModalStore();

    useEffect(() => {
        if (!data) {
            return;
        }

        onChange(data);

        return () => {
            resetData(); // clear croquis on unmount
        }
    }, [data]);

    return (
        <>
            <IonList>
                <IonItem button detail onClick={() => showModal()}>
                    <IonIcon slot={"start"} icon={drawingIcon} />
                    <IonLabel>
                        <h3>Faire un croquis</h3>
                        <p>Le croquis sera joint au rapport</p>
                    </IonLabel>
                </IonItem>

                {
                    sketch && (
                        <IonImg
                            src={sketch}
                            alt="Sketch"
                            style={{
                                border: 'solid 1px',
                                borderColor: 'var(--ion-color-primary)'
                            }}
                        />
                    )
                }
            </IonList>
        </>
    )
}

export default Photovoltaique;
