import axios, {AxiosRequestConfig} from "axios";
import {useActiveCompanyStore, useCompanyStore} from "../components/Company/company.store";

const BASE_URL = 'https://rgpfuhjkwmdedeug-mailing-service.amdp-sales.com';
// const BASE_URL = 'http://localhost:3001';
const mailingHttp = axios.create({ baseURL: BASE_URL });

const getRequestHeaders = () => {
    const entity = getActiveEntity();
    const mailingToken = mailingTokenConfig[entity];

    return {
        'x-mailing-token': mailingToken,
    };
};

const mailingTokenConfig: any = {
    '1': "e01718dc-0713-4c55-816a-950d64191258",
    '2': "b7183f21-3512-4e6e-a3ec-5ed083b4688c",
}

// Une copie en mode getter de useActiveCompanyId()
const getActiveEntity = () => {
    return useActiveCompanyStore.getState().activeCompanyId || useCompanyStore.getState().lastActiveCompanyId;
}

const requestInterceptor = (config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        ...getRequestHeaders(),
    };

    return config;
}

const errorInterceptor = (error: any) => {
    const {config, data} = error.response;

    return Promise.reject(error);
}

mailingHttp.interceptors.request.use(requestInterceptor);
mailingHttp.interceptors.response.use(undefined, errorInterceptor);

export { mailingHttp };
