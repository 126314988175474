import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";

export async function unlinkFiles(id: string, fileIds: string[]) {
    const url = Endpoint.interventionUnlinkFiles.replace(':id', id);

    await interventionHttp.post(url, {
        ids: fileIds,
    });
}
