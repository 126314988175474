import {Controller} from "react-hook-form";
import {IonDatetime, IonItem, IonLabel, IonNote, IonPopover} from "@ionic/react";
import React from "react";
import {DateTime} from "luxon";

interface GenericDatetimeButtonProps {
    name: string;
    label: string;
}

export default function GenericDatetimePicker({ name, label }: GenericDatetimeButtonProps) {
    return (
        <Controller
            name={name}
            defaultValue={null}
            render={
                (({field}) => {
                    let dateText = "jj/MM/aaaa hh:mm";

                    if (field.value) {
                        dateText = DateTime.fromISO(field.value).setLocale('fr').toLocaleString(DateTime.DATETIME_SHORT);
                    }

                    return (
                        <IonItem id={name}>
                            <IonLabel>{label}</IonLabel>
                            <IonNote slot="end">{dateText}</IonNote>

                            <IonPopover
                                trigger={name}
                                triggerAction="click"
                                keepContentsMounted={true}
                                style={{ '--width': '28%' }}
                            >
                                <IonDatetime
                                    id={name}
                                    value={field.value}
                                    onIonChange={field.onChange}
                                    showDefaultButtons={true}
                                    hourCycle={"h23"}
                                    locale="fr-FR"
                                    doneText="Valider"
                                    cancelText="Annuler"
                                    showClearButton
                                    clearText={"Effacer"}
                                >
                                    <span slot="time-label">Heure</span>
                                </IonDatetime>
                            </IonPopover>
                        </IonItem>
                    )
                })
            }
        />
    )
}
