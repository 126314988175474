import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {login} from "../../calls/User/login";
import {getMe} from "../../calls/User/getMe";
import {useSetToken, useSetUser} from "./auth.store";
import {listCompanies} from "../../calls/Company/listCompanies";
import {useSetAvailableCompanies} from "../Company/company.store";

interface LoginFormModel {
    login: string;
    password: string;
    entity?: string;
}

type UseLoginProps = {
    onLoginSuccess?(): void
}

/**
 * https://blog.logrocket.com/handling-user-authentication-redux-toolkit/
 * @param onLoginSuccess
 */
export function useLogin({ onLoginSuccess }: UseLoginProps = {}) {
    const methods = useForm<LoginFormModel>();
    const { mutateAsync, isLoading, isSuccess, isError, error } = useLoginMutation();

    const {handleSubmit} = methods;

    const handleLogin = async (data: LoginFormModel) => {
        const entity = data.login === 'demoarec' ? '2' : '1';

        await mutateAsync({
            login: data.login,
            password: data.password,
            entity,
        });

        // appel du on success ici
        onLoginSuccess && onLoginSuccess();
    };

    return {
        login: handleSubmit(handleLogin),
        loginPending: isLoading,
        loginFulfilled: isSuccess,
        loginErrored: isError,
        error,
        form: methods,
    }
}

function useLoginMutation() {
    const setToken = useSetToken();
    const setUser = useSetUser();
    const setAvailableCompanies = useSetAvailableCompanies();

    return useMutation(
        async (payoad: LoginFormModel) => {
            // Call de login
            const { data: { success: { token, entity } } } = await login(payoad);

            // Call pour récupérer plus de détail sur l'user
            const { data: me } = await getMe({ login: payoad.login, includepermissions: 1 }, token);

            // Call pour récupérer les organisations les 100 premiers
            const { items: companies, pagination } = await listCompanies(token);

            // Ensuite store des organisations récupérées
            const sortedCompanies = companies.sort(
                c => {
                    if (c.id === entity) {
                        return -1;
                    }

                    return 0;
                }
            );

            setAvailableCompanies(sortedCompanies, pagination);

            // Ensuite store des infos récupérées
            setUser(me);

            // Ensuite Store des données d'auth
            setToken(token, entity);
        }
    );
}
