import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Proposal} from "../../models/proposal.model";

export async function deleteProposalLine (id: number | string, lineid: string) {
    const url = Endpoint.proposalLine
        .replace(':id', `${id}`)
        .replace(':lineId', lineid)
    ;

    const { data } = await http.delete<Proposal>(url);

    return data;
}
