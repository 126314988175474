import {Controller} from "react-hook-form";
import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";
import React from "react";

interface GenericSelectProps {
    label: string;
    name: string;
    options: Array<{
        value: string;
        label: string;
    }>;
}

export default function GenericSelect({ name, label, options }: GenericSelectProps) {
    return (
        <Controller
            name={name}
            defaultValue={''}
            render={
                ({ field: { value, onChange, } }) => {
                    return (
                        <IonItem>
                            <IonLabel>{label}</IonLabel>
                            <IonSelect placeholder={label} value={value} onIonChange={onChange} okText={"Ok"} cancelText={"Fermer"}>
                                {
                                    options.map(
                                        ({ label, value }) => {
                                            return (
                                                <IonSelectOption key={`${name}-${value}`} value={value}>
                                                    {label}
                                                </IonSelectOption>
                                            )
                                        }
                                    )
                                }
                            </IonSelect>
                        </IonItem>
                    )
                }}
        />
    )
}
