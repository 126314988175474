import {searchInterventions, SearchInterventionsPayload} from "../../calls/Interventions/searchInterventions";
import {QueryFunction, useInfiniteQuery} from "@tanstack/react-query";
import {PaginationResultModel} from "../../models/common.model";
import {Intervention} from "../../models/intervention.model";

type Page = PaginationResultModel<Intervention>;

export function useInterventionsInfiniteQuery(payload: SearchInterventionsPayload = {}) {
    const queryKey = ['infinite', 'interventions', {payload}];
    const queryFn: QueryFunction<PaginationResultModel<Intervention>> = ({ pageParam = 0 }) => {
        return searchInterventions({
            ...payload,
            offset: pageParam,
        });
    }

    return useInfiniteQuery<Page>({
        queryKey,
        queryFn,
        getNextPageParam: (page: Page, pages: Page[]) => {
            return page.hasNextPage ? page.offset + page.limit : null;
        },
        getPreviousPageParam: (page: Page, pages: Page[]) => {
            return page.hasPrevPage ? page.offset - page.limit : null;
        },
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}
