import {CreateInterventionDto} from "./types";
import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {InterventionType, ProductTypology, VisiteTechnique} from "../../models/intervention.model";

export interface CreateVisiteTechinqueDto extends CreateInterventionDto {
    productTypologies: ProductTypology[],
    duration?: number;
    desiredInterventionDate?: string;
    particularConditions?: string;
    observations?: string;
    source?: string;
    sourceType?: InterventionType;
    report?: any;
}

export async function createVisiteTechnique(dto: CreateVisiteTechinqueDto) {
    const {data} = await interventionHttp.post<VisiteTechnique>(Endpoint.createVisiteTechnique, dto);

    return data;
}
