import {IonItem, IonLabel, IonRadio, IonRadioGroup, IonText} from "@ionic/react";
import {Controller} from "react-hook-form";
import React from "react";

interface GenericRadioProps {
    label: string;
    name: string;
    choices: Array<{
        value: string;
        label: string;
    }>
}

export default function GenericRadio({ name, label, choices }: GenericRadioProps) {
    return (
        <div style={{ padding: 5 }}>
            <IonText style={{ marginBottom: 5 }}>
                <h6>{label}</h6>
            </IonText>

            <Controller
                name={name}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonRadioGroup value={value} onIonChange={onChange}>
                                {
                                    choices.map(
                                        choice => (
                                            <IonItem key={`${name}-${choice.value}`}>
                                                <IonLabel>{choice.label}</IonLabel>
                                                <IonRadio slot="end" value={choice.value}></IonRadio>
                                            </IonItem>
                                        )
                                    )
                                }
                            </IonRadioGroup>
                        )
                    }
                }
            />
        </div>
    )
}
