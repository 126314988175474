import {MutationFunction, useMutation} from "@tanstack/react-query";
import {useActiveCompany} from "../Company/company.store";
import {sendContractByMail} from "../../calls/Mailing/sendContractByMail";
import {Proposal} from "../../models/proposal.model";
import {useGetProposalEcmFilename} from "../Common/useGetProposalEcmFilename";
import {useDownloadFromECM} from "../Common/useDownloadFromECM";
import {ThirdParty} from "../../models/third-party.model";

type MutationVariables = {
    to: string; // email destinataire
    proposal: Proposal;
    customer: ThirdParty;
}

export function useSendProposalByEmail() {
    const company = useActiveCompany();

    const getProposalFilename = useGetProposalEcmFilename();

    const { mutateAsync: download } = useDownloadFromECM();

    const mutationFn: MutationFunction<void, MutationVariables> = async ({ to, proposal, customer}) => {
        // Pas d'envoi du contract si email non existant
        if (!to) {
            return;
        }

        const { ref: proposalRef } = proposal;

        const filename = getProposalFilename(proposal, customer);

        const res = await download(filename);

        const path = `data:${res["content-type"]};base64,${res.content}`;

        const attachments = [
            {
                filename: `${company.name} Devis n° ${proposalRef}`,
                path,
            }
        ];

        await sendContractByMail({
            recipient: to,
            proposal: proposal,
            attachments,
        });
    };

    return useMutation(mutationFn);
}
