export enum InterventionType {
    VISITE_TECHNIQUE = 'VISITE_TECHNIQUE',
    POSE = 'POSE',
    SAV = 'SAV',
    ENTRETIEN = 'ENTRETIEN',
    RDVCOM = 'RDVCOM',
}

export enum AllInterventionStatus {
    TO_SCHEDULE = 'TO_SCHEDULE',
    SCHEDULED = 'SCHEDULED',
    CANCELLED = 'CANCELLED',
    PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    COMPLETED = 'COMPLETED',
    ON_THE_WAY = 'ON_THE_WAY',
    ARRIVED = 'ARRIVED',
    ETUDE_TERMINEE = 'ETUDE_TERMINEE',
    ETUDE_ANNULEE = 'ETUDE_ANNULEE',
    ARCHIVED = 'ARCHIVED'
}

export class Intervention<T extends InterventionType = any, S = any> {
    public id: string;
    public reference: string;
    public type: T;
    public proposal: InterventionProposal;
    public customer: InterventionCustomer;
    public place: InterventionPlace;
    public entity: string;
    public status: AllInterventionStatus;
    public cancellationReason?: string;
    public events: Events[];
    public date?: string;
    public reportSaved?: boolean;
    public technician?: InterventionTechnician;
    public createdAt?: string;
    public updatedAt?: string;
    public typeData: S;
    public report?: any;
}

export class InterventionProposal {
    public id?: string;
    public origin?: string;
    public originId?: string;
}

export class InterventionCustomer {
    public id: string;
    public name: string;
    public lastName?: string;
    public firstName?: string;
    public civility?: string;
    public email?: string;
    public phone?: string;
    public companyName?: string;
    public commercialId?: number;
}

export class InterventionPlace {
    public address: string;
    public zipCode: string;
    public town: string;
    public coords: number[];
}

export class InterventionTechnician {
    public id?: string;
    public name?: string;
}

export class VisiteTechniqueTypeData {
    public duration?: number;
    public desiredInterventionDate?: string;
    public particularConditions?: string;
    public observations?: string;
}

export class VisiteTechnique extends Intervention<InterventionType.VISITE_TECHNIQUE, VisiteTechniqueTypeData> {}

export class PoseTypeData {
    theoricalStartDate: string;
    theoricalEndDate: string;
}

export class Pose extends Intervention<InterventionType.POSE, PoseTypeData> {}

export class RdvComTypeData {
    batiment: {
        description: string;
        type: string;
        puissanceCompteur: string;
        endroit: string;
        superficie: string;
        typeToiture: string;
        toiture: string;
        natureCouverture: string;
        orientationToiture: string;
        pointDeRaccordementLePlusProche: string;
        etudeDeStructure: string;
    };

    visiteTechnique?: {
        id: string;
        type: string;
        reference: string;
        status: string;
    }
}

export class RdvCom extends Intervention<InterventionType.RDVCOM, RdvComTypeData> {}

export class Events {
    public type?: string;
    public date?: string;
}

interface InterventionUI {
    typeTranslation: Record<InterventionType, string>;
    typeColorMapping: Record<InterventionType, string>;
    statusTranslation: Record<AllInterventionStatus, string>;
    statusColorMapping: Record<AllInterventionStatus, string>;
}

export const interventionUI: InterventionUI = {
    typeTranslation: {
        [InterventionType.VISITE_TECHNIQUE]: 'Visite technique',
        [InterventionType.POSE]: 'Pose',
        [InterventionType.SAV]: 'SAV',
        [InterventionType.ENTRETIEN]: 'Entretien',
        [InterventionType.RDVCOM]: 'Rendez-vous Commercial',
    },
    typeColorMapping: {
        [InterventionType.VISITE_TECHNIQUE]: 'primary',
        [InterventionType.POSE]: 'success',
        [InterventionType.SAV]: 'tertiary',
        [InterventionType.ENTRETIEN]: 'warning',
        [InterventionType.RDVCOM]: 'primary',
    },
    statusTranslation: {
        [AllInterventionStatus.TO_SCHEDULE]: 'À Planifier',
        [AllInterventionStatus.SCHEDULED]: 'Programmée',
        [AllInterventionStatus.CANCELLED]: 'Annulée',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'Partiellement complétée',
        [AllInterventionStatus.COMPLETED]: 'Terminée',
        [AllInterventionStatus.ON_THE_WAY]: 'En route',
        [AllInterventionStatus.ARRIVED]: 'Arrivée',
        [AllInterventionStatus.ETUDE_TERMINEE]: 'Étude terminée',
        [AllInterventionStatus.ETUDE_ANNULEE]: 'Étude annulée',
        [AllInterventionStatus.ARCHIVED]: 'Archivée',
    },
    statusColorMapping: {
        [AllInterventionStatus.TO_SCHEDULE]: 'medium',
        [AllInterventionStatus.SCHEDULED]: 'primary',
        [AllInterventionStatus.CANCELLED]: 'danger',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'warning',
        [AllInterventionStatus.COMPLETED]: 'success',
        [AllInterventionStatus.ON_THE_WAY]: 'primary',
        [AllInterventionStatus.ARRIVED]: 'primary',
        [AllInterventionStatus.ETUDE_TERMINEE]: 'success',
        [AllInterventionStatus.ETUDE_ANNULEE]: 'danger',
        [AllInterventionStatus.ARCHIVED]: 'danger',
    }
}

export enum ProductTypology {
    PAC_AIR_AIR = 'PAC_AIR_AIR', // Pompe à chaleur Air Air
    PAC_AIR_EAU = 'PAC_AIR_EAU', // Pompe à chaleur Air Eau
    PHOTOVOLTAIC = 'PHOTOVOLTAIC', // Photovoltaïque
    BALLON_THERMO = 'BALLON_THERMO', // Ballon thermodynamique
}
