import axios, {AxiosRequestConfig} from "axios";
import {useAuthStore} from "../components/Auth/auth.store";
import {useActiveCompanyStore, useCompanyStore} from "../components/Company/company.store";

export const INTERVENTION_BASE_URL = 'https://sQYBulEmnfgK9ra-interventions-lambda-green-api.amdp-sales.com';

// export const INTERVENTION_BASE_URL = 'http://localhost:3030';

const interventionHttp = axios.create({ baseURL: INTERVENTION_BASE_URL });

const getRequestHeaders = () => {
    const token = useAuthStore.getState().token || '';
    const login = useAuthStore.getState().user?.login || '';
    const entity = getActiveEntity();

    return {
        'x-authorization-token': `${login}:${token}`,
        'x-entity': entity,
    };
};

// Une copie en mode getter de useActiveCompanyId()
const getActiveEntity = () => {
    return useActiveCompanyStore.getState().activeCompanyId || useCompanyStore.getState().lastActiveCompanyId;
}

const requestInterceptor = (config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        ...getRequestHeaders(),
    };

    return config;
}

const errorInterceptor = (error: any) => {
    const {config, data} = error.response;

    return Promise.reject(error);
}

interventionHttp.interceptors.request.use(requestInterceptor);
interventionHttp.interceptors.response.use(undefined, errorInterceptor);

export { interventionHttp };
