import {ProposalStatus} from "../../models/proposal.model";
import {IonChip, IonIcon, IonLabel} from "@ionic/react";
import React from "react";
import {alertCircleOutline, alertCircleSharp, checkmarkCircleOutline, checkmarkCircleSharp} from "ionicons/icons";

export function ProposalStatusChip({status}: { status: number }) {
    if (!status) {
        return <></>;
    }

    if (status === ProposalStatus.NOT_SIGNED) {
        return (
            <IonChip color="danger">
                <IonIcon ios={alertCircleOutline} md={alertCircleSharp} />
                <IonLabel>Refusé</IonLabel>
            </IonChip>
        )
    }

    if ([ProposalStatus.VALIDATED, ProposalStatus.SIGNED].indexOf(status) !== -1) {
        return (
            <IonChip color="success">
                <IonIcon ios={checkmarkCircleOutline} md={checkmarkCircleSharp} />
                <IonLabel>
                    {{
                        [ProposalStatus.VALIDATED]: 'Validé',
                        [ProposalStatus.SIGNED]: 'Accepté et signé'
                    }[status]}
                </IonLabel>
            </IonChip>
        )
    }

    if (status === ProposalStatus.BILLED) {
        return <IonChip color="tertiary">Facturé</IonChip>
    }

    return <></>;
}
