import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {VisiteTechnique} from "../../models/intervention.model";

export async function closeVisitetechnique(id: string) {
    const url = Endpoint.closeVisiteTechnique.replace(':id', id);

    const {data} = await interventionHttp.post<VisiteTechnique>(url, {});

    return data;
}
