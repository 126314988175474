import {isNil, omitBy} from "lodash";
import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {PaginationResultModel} from "../../models/common.model";
import {AllInterventionStatus, Intervention, InterventionType} from "../../models/intervention.model";

export type SearchInterventionsPayload = {
    // Les filtres extistants
    type?: InterventionType | string;
    status?: AllInterventionStatus | string;
    statuses?: Array<AllInterventionStatus | string>;
    technicianId?: string;
    customerId?: string;
    customerName?: string;
    source?: string;
    dateStart?: string; // iso8601
    dateEnd?: string; // iso8601
    startCreateDate?: string; // iso8601
    endCreateDate?: string; // iso8601
    offset?: number;
    limit?: number;
    sort?: string;
}

export async function searchInterventions(payload: SearchInterventionsPayload = {}) {
    const dto = omitBy(payload, isNil);

    const {data} = await interventionHttp.post<PaginationResultModel<Intervention>>(Endpoint.interventionsSearch,{
        ...dto,
        limit: dto.limit || 150,
        sort: dto.sort || '-createdAt',
    });

    return data;
}
