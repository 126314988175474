import styled from '@emotion/styled';
import BaseSignaturePad from 'signature_pad';
import {IonButton, IonButtons, IonCol, IonFooter, IonHeader, IonPage, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import {useEffect, useRef, useState} from "react";

type SignaturePadProps = {
    onDismiss?: (data?: string, role?: string) => void
}

export default function SignaturePad({ onDismiss }: SignaturePadProps) {
    const [signaturePad, setSignaturePad] = useState<BaseSignaturePad | null>();

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        setTimeout(() => {
            const ionModal = canvasRef.current!.closest('#signatureContainer');

            setWidth(ionModal!.clientWidth - 10);
            setHeight(ionModal!.clientHeight - 5);
        }, 300)
    }, [canvasRef]);

    useEffect(() => {
        function handleResize() {
            const ionModal = canvasRef.current!.closest('#signatureContainer');

            setWidth(ionModal!.clientWidth - 10);
            setHeight(ionModal!.clientHeight - 5);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (!signaturePad && canvasRef.current) {
            const signaturePad = new BaseSignaturePad(canvasRef.current, {
                minWidth: 2,
                maxWidth: 4,
                penColor: "rgb(0,0,0)",
                backgroundColor: "rgb(255,255,255)",
            });

            setSignaturePad(signaturePad);
        }
    }, [signaturePad, canvasRef]);

    const handleSubmitSignature = () => {
        const data = signaturePad?.toDataURL("image/png"); // save image as PNG

        // ne rien faire si pas de données
        if (!data) {
            return;
        }

        onDismiss && onDismiss(data, 'confirm');
    }

    const handleCancel = () => onDismiss && onDismiss(undefined, 'cancel');

    const clear = () => {
        signaturePad?.clear();
    }

    const isInitializing = (width === 0 || height === 0);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Signature
                    </IonTitle>

                    <IonButtons slot={'end'}>
                        <IonButton onClick={handleCancel}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <SignatureContainer id={'signatureContainer'}>
                {
                    isInitializing && (
                        <div
                            style={{ padding: 15, width: '100%' }}
                            className={'ion-text-center'}
                        >
                            <p>Initialisation de la signature en cours ...</p>
                        </div>
                    )
                }

                <canvas className={'signatureCanvas'} ref={canvasRef} width={width} height={height} />
            </SignatureContainer>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={'block'} fill={'clear'} onClick={clear}>Effacer</IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton expand={'block'} onClick={handleSubmitSignature}>Signer</IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}

const SignatureContainer = styled('div')`
    width: 100%;
    height: 80%;
    background-color: #fff;
    padding: 5px;

    .signatureCanvas {
        background: white;
        border: 1px solid #F0F0F0;
        border-radius: 5px;
    }
`
