import {
    IonButton,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React from "react";
import GenericTextArea from "../RapportVisiteTechnique/Form/Controls/Generic/GenericTextArea";
import {FormProvider, useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {cancelVisiteTechnique} from "../../calls/Interventions/cancelVisitetechnique";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";

interface CancelInterventionModalProps {
    interventionId: string;
    isOpen: boolean;
    handleClose?(): void;
}

export default function CancelInterventionModal(props: CancelInterventionModalProps) {
    return (
        <IonModal isOpen={props.isOpen} keepContentsMounted={false} style={{ '--height': '40%' }}>
            <MainContent {...props} />
        </IonModal>
    )
}

function MainContent({ interventionId, handleClose }: CancelInterventionModalProps) {
    const methods = useForm<{ reason: string }>();

    const { mutateAsync, isLoading } = useMutation((cancellationReason: string) => cancelVisiteTechnique(interventionId, cancellationReason));

    const onSubmit = async ({ reason }: { reason: string }) => {
        await mutateAsync(reason);
        handleClose && handleClose();
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Annulation</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p className={'ion-text-center'}>
                    L'intervention sera annulée après confirmation
                </p>

                <FormProvider {...methods}>
                    <GenericTextArea
                        label={"Raison de l'annulation"}
                        labelPosition="stacked"
                        name={"reason"}
                        rows={5}
                    />
                </FormProvider>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={'full'} fill="clear" onClick={handleClose}>
                            Fermer
                        </IonButton>
                    </IonCol>

                    <IonCol>
                        <IonLoadingButton
                            loading={isLoading}
                            expand={'full'}
                            color={'danger'}
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            Confirmer annulation
                        </IonLoadingButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </>
    )
}
