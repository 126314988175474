import axios from "axios";

const url = 'https://apicarto.ign.fr/api/codes-postaux/communes/:cp';

interface CodePostal {
    codePostal: string;
    codeCommune: string;
    nomCommune: string;
    libelleAcheminement: string;
}

export async function getCodePostauxCommune(cp: string) {
    const { data } = await axios.get<CodePostal[]>(url.replace(':cp', cp));

    return data;
    // return (
    //     [
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64037",
    //             "nomCommune": "Arbus",
    //             "libelleAcheminement": "ARBUS"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64060",
    //             "nomCommune": "Artiguelouve",
    //             "libelleAcheminement": "ARTIGUELOUVE"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64073",
    //             "nomCommune": "Aubin",
    //             "libelleAcheminement": "AUBIN"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64080",
    //             "nomCommune": "Aussevielle",
    //             "libelleAcheminement": "AUSSEVIELLE"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64121",
    //             "nomCommune": "Beyrie-en-Béarn",
    //             "libelleAcheminement": "BEYRIE EN BEARN"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64142",
    //             "nomCommune": "Bougarber",
    //             "libelleAcheminement": "BOUGARBER"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64183",
    //             "nomCommune": "Caubios-Loos",
    //             "libelleAcheminement": "CAUBIOS LOOS"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64198",
    //             "nomCommune": "Denguin",
    //             "libelleAcheminement": "DENGUIN"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64335",
    //             "nomCommune": "Lescar",
    //             "libelleAcheminement": "LESCAR"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64374",
    //             "nomCommune": "Mazerolles",
    //             "libelleAcheminement": "MAZEROLLES"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64387",
    //             "nomCommune": "Momas",
    //             "libelleAcheminement": "MOMAS"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64448",
    //             "nomCommune": "Poey-de-Lescar",
    //             "libelleAcheminement": "POEY DE LESCAR"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64511",
    //             "nomCommune": "Sauvagnon",
    //             "libelleAcheminement": "SAUVAGNON"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64525",
    //             "nomCommune": "Siros",
    //             "libelleAcheminement": "SIROS"
    //         },
    //         {
    //             "codePostal": "64230",
    //             "codeCommune": "64549",
    //             "nomCommune": "Uzein",
    //             "libelleAcheminement": "UZEIN"
    //         }
    //     ]
    // )
}
