import type {TextFieldProps} from "@mui/material";
import {FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import {FieldError} from "react-hook-form";
import OptionalFormLabel from "./OptionalFormLabel";

interface GenericTextFieldControlProps {
    label: string;
    optional?: boolean;
    TextFieldProps?: TextFieldProps;
    error?: FieldError;
}

export default function GenericTextFieldControl({ label, optional, TextFieldProps, error }: GenericTextFieldControlProps) {
    const errored = !!error;

    return (
        <FormControl variant={"outlined"} fullWidth error={errored}>
            {
                !optional
                    ? (
                        <FormLabel>{label}</FormLabel>
                    )
                    : (
                        <OptionalFormLabel>{label}</OptionalFormLabel>
                    )
            }

            <TextField
                {...TextFieldProps}
                variant="outlined"
                size={"small"}
                error={errored}
            />

            {
                error && (
                    <FormHelperText>
                        {error.message}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}