import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {RapportVisiteTechniqueFormModel} from "../components/RapportVisiteTechnique/Form";
import {DevTool} from "@hookform/devtools";
import {RapportVisiteTechniqueContext} from "../components/RapportVisiteTechnique/RapportVisiteTechniqueContext";
import {ProductTypology} from "../models/intervention.model";
import {useMutation} from "@tanstack/react-query";
import {editReport} from "../calls/Interventions/editReport";
import Photovoltaique from "../components/RapportVisiteTechnique/Photovoltaique";

interface RapportVisiteTechniqueProps {
    interventionId: string;
    report?: any,
    proposalId?: string;
    typologies: ProductTypology[];
    onDismiss?(reason: 'cancel' | 'submit'): void;
}

const RapportVisiteTechnique: React.FC<RapportVisiteTechniqueProps> = (props: RapportVisiteTechniqueProps) => {
    const methods = useForm<RapportVisiteTechniqueFormModel>({
        defaultValues: props.report,
    });

    const {handleSubmit, watch} = methods;

    const { mutateAsync: saveChanges } = useMutation((report: RapportVisiteTechniqueFormModel) => editReport(props.interventionId, report));

    const onSubmit = (data: RapportVisiteTechniqueFormModel) => saveChanges(data);

    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)())
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    const [currentStep, setCurrentStep] = useState<number>(0);

    const totalStep = props.typologies.length;
    const isLastStep = currentStep === (totalStep - 1);

    const next = () => setCurrentStep(
        currentStep < totalStep - 1
            ? currentStep + 1
            : currentStep
    );
    const prev = () => setCurrentStep(
        currentStep > 0
            ? currentStep - 1
            : currentStep
    );

    return (
        <RapportVisiteTechniqueContext.Provider value={{
            ...props,
            currentStep,
            isLastStep,
            next,
            prev,
        }}>
            <FormProvider {...methods}>
                <Photovoltaique />
            </FormProvider>

            <DevTool control={methods.control} />
        </RapportVisiteTechniqueContext.Provider>
    )
}

export default RapportVisiteTechnique;
