import {cadastreHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface FillCerfaDpDto {
    commune: string;
    section: string;
    parcelle: string;
    prefixe: string;
    contenance: number;
    chantier: {
        street: string,
        address: string,
        postalCode: string,
        town: string,
    }
    qtyPanneaux: number;
}

export async function fillCerfaDp(dto: FillCerfaDpDto) {
    const url = Endpoint.cadastreFillCerfaDp;

    const {data: blob} = await cadastreHttp.post(url, dto, {
        responseType: 'blob'
    });

    return {
        filename: "CERFA 13703_09.pdf",
        blob,
    };
}
