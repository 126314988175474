import {Endpoint} from "../../endpoint.enum";
import {interventionHttp} from "../../../http";
import {RdvCom} from "../../../models/intervention.model";

export interface EditRdvComDto {
    date?: string;
}

export async function editRdvCom(id: string, dto: EditRdvComDto = {}) {
    const url = Endpoint.editRdvCom.replace(':id', id);

    const {data} = await interventionHttp.post<RdvCom>(url, dto);

    return data;
}
