import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getProposal} from "../../calls/Proposals/getProposal";

export function useProposalDetail(proposalId: string) {
    return useQuery(['proposal', proposalId], ({queryKey}) => {
        return getProposal(queryKey[1] as string);
    });
}

export function useInvalidateProposalDetail(proposalId: string) {
    const qc = useQueryClient();

    return () => qc.invalidateQueries({ queryKey: ['proposal', proposalId] });
}
