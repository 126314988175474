import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Intervention, VisiteTechnique} from "../../models/intervention.model";

export async function cancelVisiteTechnique(id: string, cancellationReason: string) {
    const url = Endpoint.cancelVisiteTechnique.replace(':id', id);

    const {data} = await interventionHttp.post<VisiteTechnique>(url, { cancellationReason });

    return data;
}
