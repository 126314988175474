import {Controller} from "react-hook-form";
import {IonAlert, IonIcon, IonInput, IonItem, IonLabel, IonText} from "@ionic/react";
import { useLogin } from "./useLogin";
import {alertCircleOutline, alertCircleSharp, eyeOffSharp, eyeSharp} from "ionicons/icons";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import React, {useEffect, useState} from "react";

export default function LoginForm() {
    const { login, loginPending, loginFulfilled, loginErrored, error, form: { reset, control, formState: { errors } } } = useLogin();

    useEffect(() => {
        if (!loginFulfilled) {
            return;
        }

        reset();
    }, [loginFulfilled]);

    return (
        <>
            {
                loginErrored && (
                    <ErrorAlert error={error} />
                )
            }
            <form onSubmit={login} noValidate>
                <Controller
                    control={control}
                    rules={{ required: "Nom d'utilisateur obligatoire" }}
                    name="login"
                    render={({ field: { onChange, value } }) => {
                        return (
                            <IonInput
                                placeholder="Nom d'utilisateur"
                                value={value}
                                onIonChange={e => onChange(e.detail.value)}
                            />
                        )
                    }}
                />

                {
                    errors && errors.login && (
                        <IonText color="danger" style={{ display: 'flex' }} className="ion-align-items-center ion-justify-content-center">
                            <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                            <small style={{ marginLeft: 2 }}>{errors.login.message}</small>
                        </IonText>
                    )
                }

                <Controller
                    control={control}
                    rules={{ required: "Mot de passe obligatoire" }}
                    name="password"
                    render={({ field: { onChange, value } }) => (
                        <PasswordInput
                            value={value}
                            onIonChange={onChange}
                        />
                    )}
                />

                {
                    errors && errors.password && (
                        <IonText color="danger" style={{ display: 'flex' }} className="ion-align-items-center ion-justify-content-center">
                            <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                            <small style={{ marginLeft: 2 }}>{errors.password.message}</small>
                        </IonText>
                    )
                }

                <div className="ion-margin-top">
                    <IonLoadingButton
                        type={"submit"}
                        loading={loginPending}
                        disabled={loginFulfilled}
                        expand="block"
                    >Se connecter</IonLoadingButton>
                </div>

                <input type="submit" style={{
                    position: 'absolute',
                    visibility: 'hidden',
                }} />
            </form>
        </>
    )
}

function PasswordInput({ value, onIonChange }: { value: string, onIonChange(value: string): void }) {
    const [passwordShown, setPasswordShown] = useState(false);
    const inputType = passwordShown ? 'text' : 'password';
    const text = passwordShown ? 'Cacher mot de passe' : 'Afficher mot de passe';
    const icon = passwordShown ? eyeOffSharp : eyeSharp;

    return (
        <>
            <IonInput
                type={inputType}
                placeholder="Mot de passe"
                value={value}
                onIonChange={e => onIonChange(e.detail.value as string)}
            />

            <IonItem lines="none" button onClick={() => setPasswordShown(prev => !prev)}>
                <IonIcon icon={icon} slot="start"></IonIcon>
                <IonLabel>{text}</IonLabel>
            </IonItem>
        </>
    )
}

function ErrorAlert({ error }: { error: any }) {
    const [showAlert, setShowAlert] = useState(true)
    let msg = "Erreur d'authentification, veuillez réessayer";

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 403) {
            msg = "Nous n'avons pas pu vous identifier, vérifiez votre identifiant et/ou mot de passe";
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }

    return (
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header="Connexion impossible"
            message={msg}
            buttons={['OK']}
        />
    )
}
