import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import {
    addOutline,
    addSharp,
    alertCircleOutline,
    alertCircleSharp,
    checkmarkDoneCircleOutline,
    checkmarkDoneCircleSharp,
    listCircleOutline,
    listCircleSharp,
    logOutOutline,
    logOutSharp,
    mapOutline,
    mapSharp,
    personCircleOutline,
    personCircleSharp,
    speedometerOutline,
    speedometerSharp,
    timeOutline,
    timerSharp,
    trashBinOutline,
    trashBinSharp
} from 'ionicons/icons';
import './Menu.css';
import Wordmark from "./Wordmark";
import {useHistory} from "react-router";
import {useLogout, useUser, useUserRights} from "./Auth/auth.store";
import CompanySwitchButton from "./Company/CompanySwitchButton";
import React from "react";
import {AllInterventionStatus} from "../models/intervention.model";
import InterventionStatusCountBadge from "./Interventions/InterventionStatusBadge";
import {userToString} from "../models/user.model";

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    badge?: React.ReactNode;
}

const appPages: AppPage[] = [
    {
        title: 'Dashboard',
        url: '/app/Dashboard',
        iosIcon: speedometerOutline,
        mdIcon: speedometerSharp
    },
    {
        title: 'Carte',
        url: '/app/Map',
        iosIcon: mapOutline,
        mdIcon: mapSharp
    },
];

const interventionsPages: Array<AppPage & { statuses?: Array<AllInterventionStatus | string> }> = [
    {
        title: 'Toutes',
        url: '/app/Interventions',
        iosIcon: listCircleOutline,
        mdIcon: listCircleSharp,
        // badge: <InterventionStatusCountBadge status={"all"} />,
    },
    {
        title: 'À Faire',
        url: `/app/Interventions`,
        iosIcon: timeOutline,
        mdIcon: timerSharp,
        statuses: [
            AllInterventionStatus.TO_SCHEDULE,
            AllInterventionStatus.SCHEDULED
        ],
        badge: <InterventionStatusCountBadge status={[
            AllInterventionStatus.TO_SCHEDULE,
            AllInterventionStatus.SCHEDULED
        ]} color={"primary"} />,
    },
    {
        title: 'Études terminées',
        url: `/app/Interventions`,
        iosIcon: checkmarkDoneCircleOutline,
        mdIcon: checkmarkDoneCircleSharp,
        statuses: [
            AllInterventionStatus.ETUDE_TERMINEE,
        ],
        badge: <InterventionStatusCountBadge status={[AllInterventionStatus.ETUDE_TERMINEE]} color={"success"} />,
    },
    {
        title: 'Études annulées',
        url: `/app/Interventions`,
        iosIcon: alertCircleOutline,
        mdIcon: alertCircleSharp,
        statuses: [
            AllInterventionStatus.ETUDE_ANNULEE,
        ],
        // badge: <InterventionStatusCountBadge status={[AllInterventionStatus.ETUDE_ANNULEE]} />,
    },
    {
        title: 'Archivées',
        url: `/app/Interventions`,
        iosIcon: trashBinOutline,
        mdIcon: trashBinSharp,
        statuses: [
            AllInterventionStatus.ARCHIVED,
        ],
        badge: <InterventionStatusCountBadge status={[AllInterventionStatus.ARCHIVED]} />,
    },
]

const Menu: React.FC = () => {
    const location = useLocation();
    const logoutAndRedirect = useLogoutAndRedirect();

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list" style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 0,
                    paddingBottom: 0,
                }} lines={"none"}>
                    <IonListHeader className={"ion-align-items-center ion-margin-bottom"}>
                        <Wordmark />
                    </IonListHeader>

                    {
                        appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })
                    }

                    <IonItemDivider>
                        <IonLabel>Interventions</IonLabel>
                    </IonItemDivider>

                    <CreateInterventionButton />

                    {
                        interventionsPages.map((appPage, index) => {
                            const currentRouterLink = `${location.pathname}${location.search}`;

                            const routerLink = appPage?.statuses ? `${appPage.url}?status=${appPage.statuses?.join()}` : appPage.url;

                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem className={currentRouterLink === routerLink ? 'selected' : ''} routerLink={routerLink} routerDirection="none" lines="none" detail={false}>
                                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                        {appPage.badge}
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })
                    }

                    <CompanySwitchButton style={{
                        marginTop: 'auto',
                    }} />

                    <UserItem />

                    <IonMenuToggle key={"logout"} autoHide={false}>
                        <IonItem detail={false} button lines="none" onClick={logoutAndRedirect}>
                            <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
                            <IonLabel>{"Déconnexion"}</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

function UserItem() {
    const user = useUser();

    return (
        <IonItem id="company-modal" detail={false} lines={"full"} button>
            <IonIcon slot="start" md={personCircleSharp} ios={personCircleOutline} />
            <IonLabel>
                <h2>{userToString(user)}</h2>
                <p>@{user.login}</p>
            </IonLabel>
        </IonItem>
    )
}

function CreateInterventionButton() {
    const rights = useUserRights();

    // Afficher le bouton uniquement si cette autorisation est activée
    if (!rights?.amdpsales?.intervention.create_from_mobile) {
        return null;
    }

    return (
        <IonMenuToggle key={"CreateInterventionButton"} autoHide={false}>
            <IonItem style={{ marginTop: 15, marginBottom: 15 }} detail={false} button lines="none" color="primary" routerLink={"/app/Interventions/New"} routerDirection="none">
                <IonIcon color="light" slot={"start"} md={addSharp} ios={addOutline} />
                <IonLabel>Créer RDV</IonLabel>
            </IonItem>
        </IonMenuToggle>
    )
}

export default Menu;

function useLogoutAndRedirect() {
    let history = useHistory();
    const logout = useLogout();

    return () => {
        // reset du state
        logout();

        // redirection après logout
        history.push('/Login');
    }
}
