import {useMutation} from "@tanstack/react-query";
import {Intervention, InterventionType} from "../../models/intervention.model";
import {CreateVisiteTechinqueDto, createVisiteTechnique} from "../../calls/Interventions/createVisiteTechnique";
import {createRdvCom, CreateRdvCommDto} from "../../calls/Interventions/RdvCom/createRdvCom";
import {createThirdParty} from "../../calls/ThirdParties/createThirdParty";

export function useCreateNewIntervention(type: InterventionType) {
    return useMutation(async (dto: CreateVisiteTechinqueDto | CreateRdvCommDto): Promise<Intervention> => {
        switch (type) {
            case InterventionType.RDVCOM:
                const { customer, place, entity } = dto as CreateRdvCommDto;

                const { thirdpartyId } = await createThirdParty({
                    name: customer.companyName || '',
                    civility_id: customer.civility,
                    name_bis: customer.lastName,
                    firstname: customer.firstName,
                    email: customer.email,
                    phone: customer.phone,
                    client: '2', //On part du principe que c'est un propsect si on passe par le RDVCOM pour le créer
                    address: place.address || '',
                    zip: place.zipCode,
                    town: place.town,
                    commercial_id: customer.commercialId,
                    typent_id: 8, // 8 = particulier = on demande à créer le contact également
                    entity: +entity,
                    array_options: {
                        civilite: customer.civility,
                        // personal_mobile_phone: data.personalMobilePhone,
                        // business_mobile_phone: data.businessMobilePhone,
                        lat: place.coords[1] || 0,
                        lng: place.coords[0] || 0,
                    }
                });

                const rdv = await createRdvCom({
                    ...dto,
                    customer: {
                        ...customer,
                        id: thirdpartyId.toString(), // attache l'intervention au nouveau client
                    }
                } as CreateRdvCommDto);

                return rdv;

            case InterventionType.VISITE_TECHNIQUE:
                return createVisiteTechnique(dto as CreateVisiteTechinqueDto);

            case InterventionType.POSE:
                throw new Error(`Oops unable to create intervention of type ${type}`);
                // return createPose(dto as CreatePoseDto);

            case InterventionType.SAV:
                throw new Error(`Oops unable to create intervention of type ${type}`);
                // return createSav(dto);

            case InterventionType.ENTRETIEN:
                throw new Error(`Oops unable to create intervention of type ${type}`);
                // return createEntretien(dto);
        }
    });
}
