import {
    IonButton, IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage, IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar, useIonLoading, useIonToast
} from "@ionic/react";
import {cameraOutline, cameraSharp} from "ionicons/icons";
import React from "react";
import InterventionLabel from "../Shared/InterventionLabel";

export function EtatLieux({ onDismiss }: any) {
    const [present, dismiss] = useIonLoading();

    const [presentToast] = useIonToast();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>État des lieux</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    <IonItem button detail={true}>
                        <IonIcon slot={'start'} ios={cameraOutline} md={cameraSharp} />
                        <IonLabel>Faire photos</IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonLabel position={"stacked"}>Commentaires</IonLabel>
                        <IonTextarea rows={4} placeholder="Commentaires"></IonTextarea>
                    </IonItem>
                </IonList>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={"block"} fill="clear" onClick={() => onDismiss && onDismiss()}>Annuler</IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            expand={"block"}
                            onClick={() => present({
                                message: 'Sauvegarde état des lieux en cours',
                                duration: 3000,
                                onDidDismiss() {
                                    onDismiss!(); // Dismiss le modal

                                    presentToast({
                                        message: 'État des lieux sauvegardé avec succès',
                                        duration: 1500,
                                        position: 'bottom'
                                    });
                                }
                            })}>Enregistrer</IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}
