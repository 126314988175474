import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonSkeletonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
    useIonModal,
    useIonRouter,
    useIonToast,
    useIonViewDidEnter
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import RapportVisiteTechnique from "./RapportVisiteTechnique";
import {
    addOutline,
    addSharp,
    alertCircleOutline,
    alertCircleSharp,
    attachOutline,
    attachSharp,
    callOutline,
    callSharp,
    cameraSharp,
    checkmarkCircleOutline,
    checkmarkCircleSharp,
    checkmarkDoneCircleSharp,
    cloudDownloadOutline,
    cloudDownloadSharp,
    createOutline,
    createSharp,
    earthOutline,
    earthSharp,
    locationOutline,
    locationSharp,
    navigateOutline,
    navigateSharp,
    pauseSharp,
    playOutline,
    playSharp,
    sadOutline,
    sadSharp,
    trashBinOutline,
    trashBinSharp,
    trashOutline,
    trashSharp,
    warningOutline,
    warningSharp
} from "ionicons/icons";
import {useParams} from "react-router";
import InterventionLabel from "../components/Shared/InterventionLabel";
import CompteRenduSavAndEntretient from "../components/SavAndEntretient/CompteRenduSavAndEntretient";
import {EtatLieux} from "../components/Pose/EtatLieux";
import CompteRenduInstallation from "../components/Pose/CompteRenduInstallation";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getIntervention} from "../calls/Interventions/getIntervention";
import {
    AllInterventionStatus,
    Intervention,
    InterventionType,
    ProductTypology,
    RdvCom
} from "../models/intervention.model";
import {useProposalDetail} from "../components/AffaireDetail/useProposalDetail";
import {DateTime} from "luxon";
import {usePhotoGallery} from "../hooks/usePhotoGallery";
import {FileToUploadDto, interventionAttachFiles} from "../calls/Interventions/attachInterventionFiles";
import {useActiveCompany} from "../components/Company/company.store";
import {getInterventionFiles} from "../calls/Interventions/getInterventionFiles";
import {cancelVisiteTechnique} from "../calls/Interventions/cancelVisitetechnique";
import Cadastre from "../components/RapportVisiteTechnique/Cadastre";
import styled from "@emotion/styled";
import {saveAs} from "file-saver";
import * as blobUtil from 'blob-util';
import FilerobotImageEditor, {TABS, TOOLS,} from 'react-filerobot-image-editor';
import {CameraSource} from "@capacitor/camera";
import useLongPress from "../hooks/useLongPress";
import {CreateVisiteTechinqueDto, createVisiteTechnique} from "../calls/Interventions/createVisiteTechnique";
import {searchInterventions} from "../calls/Interventions/searchInterventions";
import {agentIsOnTheWayToIntervention} from "../calls/Interventions/agentIsOnTheWayToIntervention";
import {agentArrivedAtTheInterventionPlace} from "../calls/Interventions/agentArrivedAtTheInterventionPlace";
import {agentIsLeavingInterventionPlace} from "../calls/Interventions/agentIsLeavingInterventionPlace";
import {closeRdvCom} from "../calls/Interventions/closeRdvCom";
import LocalizeMe from "../components/RapportVisiteTechnique/LocalizeMe";
import CancelInterventionModal from "../components/Interventions/CancelInterventionModal";
import IonLoadingButton from "../components/Common/Lab/IonLoadingButton";
import {useHistory} from "react-router-dom";
import SketchingPadModal from "../components/Sketching/SketchingPadModal";
import FilePicker, {docsFileType, FilePickerProps} from "../components/Common/Lab/FilePicker";
import {editVisiteTechnique} from "../calls/Interventions/editVisiteTechnique";
import {editRdvCom} from "../calls/Interventions/RdvCom/editRdvCom";
import JSZip from "jszip";
import {closeVisitetechnique} from "../calls/Interventions/closeVisitetechnique";
import {setInterventionStatus} from "../calls/Interventions/setInterventionStatus";
import {unlinkFiles} from "../calls/Interventions/unlinkFiles";

const InterventionDetail: React.FC = () => {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });
    const { id } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: intervention } = useInterventionDetail(id);

    if (isLoading) {
        return <></>;
    }

    if (isError) {
        return <></>;
    }

    return (
        <InterventionPage intervention={intervention} />
    )
};

export default InterventionDetail;

function InterventionPage({ intervention }: { intervention: Intervention }) {
    const { id, customer, proposal, reference, place: { address, zipCode, town, coords }, type, typeData } = intervention;

    const addressText = [
        address,
        `${zipCode || ''} ${town || ''}`.trim(),
    ].filter(i => !!i).join(', ');

    // const { isLoading, isError, typologies } = useAllInterventionProductTypologies(proposal!.id as string);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>
                        <InterventionLabel type={type} />
                    </IonTitle>

                    <IonButtons slot="end">
                        {
                            intervention?.status !== AllInterventionStatus.ARCHIVED && (
                                <ArchiveButton id={intervention.id} />
                            )
                        }
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {
                        type === InterventionType.RDVCOM && (
                            <IonListHeader>
                                <IonLabel>Informations RDV</IonLabel>
                            </IonListHeader>
                        )
                    }

                    {
                        intervention?.cancellationReason && (
                            <IonItem color={"danger"}>
                                <IonIcon md={alertCircleSharp} ios={alertCircleOutline} slot="start"></IonIcon>
                                <IonLabel className="ion-text-wrap">
                                    <h3><b><InterventionLabel type={type} /> annulée :</b></h3>
                                    <p>{intervention?.cancellationReason}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    }

                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h3>Nom du gérant</h3>
                            <p>{customer.civility || ''} {customer.firstName || ''} {customer.lastName || ''}</p>
                        </IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h3>Raison Sociale</h3>
                            <p>{customer.companyName}</p>
                        </IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h3>Adresse</h3>
                            <p>{addressText}</p>
                        </IonLabel>
                        <IonIcon slot={"end"} ios={navigateOutline} md={navigateSharp} />
                    </IonItem>

                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h3>Téléphone</h3>
                            <p>{customer?.phone}</p>
                        </IonLabel>
                        <IonIcon slot={"end"} ios={callOutline} md={callSharp} />
                    </IonItem>

                    {
                        type === InterventionType.POSE && (
                            <>
                                <IonGrid className= "ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonLabel>
                                                    <h3>Début Pose</h3>
                                                    <p>{formatPoseDate(typeData.theoricalStartDate)}</p>
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonLabel>
                                                    <h3>Fin Pose</h3>
                                                    <p>{formatPoseDate(typeData.theoricalEndDate)}</p>
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>

                                {/*<IonItem button detail={true} className={'ion-text-wrap'}*/}
                                {/*         routerLink={`/app/InstallationProcedures?selectedTypology=${typologies[0] || ''}`}*/}
                                {/*         routerDirection={'forward'}*/}
                                {/*>*/}
                                {/*    <IonIcon slot={'start'} md={informationCircleSharp} ios={informationCircleOutline} />*/}
                                {/*    <IonLabel>*/}
                                {/*        <h3>Procédure installation</h3>*/}
                                {/*        <p>Consulter la liste des procédures d'installation</p>*/}
                                {/*    </IonLabel>*/}
                                {/*</IonItem>*/}
                            </>
                        )
                    }

                    {
                        type !== InterventionType.POSE && (
                            <ScheduleInterventionDateItem {...intervention} />
                        )
                    }

                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h3>Référence</h3>
                            <p>{intervention.reference}</p>
                        </IonLabel>
                    </IonItem>
                </IonList>

                {
                    (proposal && type === InterventionType.VISITE_TECHNIQUE) && (
                        <InterventionProposalItem proposalId={proposal.id!} />
                    )
                }

                {/*<ProductList proposalId={proposal.id as string} />*/}

                {
                    type === InterventionType.RDVCOM && (
                        <InterventionBatiment {...intervention as any} />
                    )
                }

                {
                    type === InterventionType.RDVCOM && (
                        <VisiteTechniqueList {...intervention as any} />
                    )
                }

                {
                    type === InterventionType.VISITE_TECHNIQUE && (
                        <>
                            <RapportVisiteTechnique
                                interventionId={intervention?.id}
                                report={intervention?.report}
                                typologies={intervention?.typeData.productTypologies || []}
                            />

                            <IonList>
                                <IonListHeader>
                                    <IonLabel>Géolocalisation et Cadastre</IonLabel>
                                </IonListHeader>

                                <InterventionLocalizeMe id={id} reference={reference} customerName={customer.name} />

                                <InterventionCadastre
                                    id={id}
                                    reference={reference}
                                    customerName={customer.name}
                                    // proposalId={proposal.id as string}
                                    chantier={{ address, zipCode, town, coords } as any}
                                />
                            </IonList>
                        </>
                    )
                }

                {
                    type !== InterventionType.RDVCOM && (
                        <>
                            <InterventionPhotos id={id} reference={reference} customerName={customer.name} />

                            <InterventionPdfDocuments
                                id={id}
                                customerName={customer.name}
                            />
                        </>
                    )
                }

                <IonToolbar>
                    <InterventionButton id={id} type={type} />
                </IonToolbar>
            </IonContent>

            <SketchingPadModal />
        </IonPage>
    )
}

function ScheduleInterventionDateItem(intervention: Intervention) {
    const { type, date, status } = intervention;

    const [newDate, setNewDate] = useState<string | undefined>(date);

    const invalidateInvernetionDetail = useInvalidateInterventionDetail(intervention.id);

    const [present] = useIonToast();

    const { mutateAsync } = useMutation(async ({ newDate }: { newDate: string }) => {
        switch (type) {
            case InterventionType.VISITE_TECHNIQUE:
                return editVisiteTechnique(intervention.id, { date: newDate });

            case InterventionType.RDVCOM:
                return editRdvCom(intervention.id, { date: newDate });
        }

        throw new Error(`No mutation available for intervention ${type}`);
    });


    const isDateEnabled = (d: string) => {
        const now = new Date().setHours(0,0,0,0);
        const date = new Date(d).setHours(0,0,0,0);
        return date >= now;
    }

    const onDateChanged = async (d: string) => {
        setNewDate(d);

        try {
            await mutateAsync({ newDate: new Date(d).toISOString() });

            await present({
                message: 'Date modifiée avec succès',
                duration: 2000,
                position: 'bottom',
            });

            await invalidateInvernetionDetail();
        } catch (e) {
            await present({
                message: 'Une erreur est survenue lors de la modification de la date',
                duration: 2500,
                position: 'bottom',
            });
        }
    }

    // Les status autorisés pour la modification des dates
    const allowedStatuses = [
        AllInterventionStatus.TO_SCHEDULE,
        AllInterventionStatus.SCHEDULED,
    ];

    const [isOpen, setIsOpen] = useState(false);

    if (date && allowedStatuses.indexOf(status) < 0) {
        return (
            <IonItem>
                <IonLabel>
                    <h3>Date <InterventionLabel type={type} /></h3>
                    <p>{formatDate(date)}</p>
                </IonLabel>
            </IonItem>
        )
    }

    return (
        <>
            <IonItem button detail onClick={() => setIsOpen(true)}>
                <IonLabel>
                    <h3>Date <InterventionLabel type={type} /></h3>
                    <p>{date ? formatDate(date) : 'Non planifié'}</p>
                </IonLabel>
            </IonItem>

            <IonPopover
                isOpen={isOpen}
                onWillDismiss={() => setIsOpen(false)}
                keepContentsMounted={false}
            >
                <IonDatetime
                    value={newDate}
                    onIonChange={e => onDateChanged(e.detail.value as string)}
                    showDefaultButtons={true}
                    hourCycle={"h23"}
                    locale="fr-FR"
                    doneText="Valider"
                    cancelText="Annuler"
                    showClearButton
                    clearText={"Effacer"}
                    isDateEnabled={isDateEnabled}
                >
                    <span slot="time-label">Heure</span>
                </IonDatetime>
            </IonPopover>
        </>
    )
}

function InterventionProposalItem({ proposalId }: { proposalId: string }) {
    const { isLoading, data: proposal } = useProposalDetail(proposalId);

    const isProposalValidated = proposal?.status === 1; // En attente d'une décision: acceptée et signature ou rejetée par le client
    const isProposalSigned = proposal?.status === 2;
    const isProposalNotSigned = proposal?.status === 3;

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Proposition commerciale</IonLabel>
            </IonListHeader>

            {
                isLoading ? (
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated={true} style={{ width: '45%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated={true} style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                ) : (
                    <IonItem button detail routerLink={`/app/Proposal/${proposalId}`} routerDirection={"forward"}>
                        {
                            isProposalValidated && (
                                <IonIcon size="large" slot={"start"} md={warningSharp} ios={warningOutline} color={"warning"} />
                            )
                        }

                        {
                            isProposalSigned && (
                                <IonIcon size="large" slot={"start"} md={checkmarkCircleSharp} ios={checkmarkCircleOutline} color={"success"} />
                            )
                        }

                        {
                            isProposalNotSigned && (
                                <IonIcon size="large" slot={"start"} md={sadSharp} ios={sadOutline} color={"danger"} />
                            )
                        }

                        <IonLabel className="ion-text-wrap">
                            <h3>Devis n° {proposal!.ref}</h3>
                            <p>
                                {
                                    isProposalValidated && (
                                        <>Devis en attente de signature du client</>
                                    )
                                }

                                {
                                    isProposalSigned && (
                                        <>Devis signé par le client</>
                                    )
                                }

                                {
                                    isProposalNotSigned && (
                                        <>Devis refusé par le client</>
                                    )
                                }
                            </p>
                        </IonLabel>
                    </IonItem>
                )
            }
        </IonList>
    )
}

function InterventionBatiment(intervention: RdvCom) {
    const { batiment } = intervention.typeData;

    const items = [
        { field: 'type', label: 'Type de Batiment' },
        { field: 'puissanceCompteur', label: 'Puissance du compteur' },
        { field: 'superficie', label: 'La superficie' },
        { field: 'typeToiture', label: 'Type de toiture' },
        { field: 'natureCouverture', label: 'Nature de la couverure' },
        {
            field: 'orientationToiture',
            label: "L'orientation de La toiture",
            render(value: string) {
                return {
                    N: 'Nord',
                    NE: 'Nord-Est',
                    NO: 'Nord-Ouest',
                    S: 'Sud',
                    SE: 'Sud-Est',
                    SO: 'Sud-Ouest',
                    E: 'Est',
                    O: 'Ouest',
                    EO: 'Est-Ouest',
                }[value];
            }
        },
        { field: 'pointDeRaccordementLePlusProche', label: 'Le point de raccordement le plus proche de' },
        { field: 'etudeDeStructure', label: 'Etude de structure' },
    ];

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Batiment et Toiture</IonLabel>
            </IonListHeader>

            {
                items.map(
                    item => {
                        return (
                            <IonItem key={item.field}>
                                <IonLabel className="ion-text-wrap">
                                    <h3>{item.label}</h3>
                                    <p>{
                                        item.render
                                            ? item.render((batiment as any)[item.field])
                                            : ((batiment as any)[item.field] || 'N/A')
                                    }</p>
                                </IonLabel>
                            </IonItem>
                        )
                    }
                )
            }
        </IonList>
    )
}

function VisiteTechniqueList(intervention: RdvCom) {
    const source = intervention.id;
    const { data, refetch, isLoading } = useQuery(['interventions', { source }], () => searchInterventions({ source }));

    const items = data?.docs || [];

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Visites Techniques ({ data?.totalDocs || 0 })</IonLabel>
            </IonListHeader>

            {
                !isLoading && items.length === 0 && (
                    <IonItem>
                        <IonLabel>
                            <p>Pas de visites techniques trouvées</p>
                        </IonLabel>
                    </IonItem>
                )
            }

            {
                items.map(
                    item => {
                        return (
                            <IonItem key={item.id} detail routerLink={`../Interventions/${item.id}`} routerDirection={"forward"}>
                                <IonLabel className="ion-text-wrap">
                                    <h3>VT n°{item.reference}</h3>
                                    <p>{item.customer.name}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    }
                )
            }
        </IonList>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');
const formatPoseDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toLocaleString(DateTime.DATE_MED);

function ProductList({ proposalId }: { proposalId: string }) {
    const { isLoading, data: proposal } = useProposalDetail(proposalId);

    if (isLoading) {
        return (
            <></>
        )
    }

    const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0'); // On ne filtre que les produits dans les lignes du proposal

    return (
        <IonList>
            {
                products.map(
                    (product, index) => (
                        <IonItem key={product.id}>
                            <IonLabel className="ion-text-wrap">
                                <h3>{product.product_label}</h3>
                                <p>Produit {products.length === 1 ? '' : `${(index+1)} / ${products.length}`}</p>
                            </IonLabel>
                        </IonItem>
                    )
                )
            }
        </IonList>
    )
}

interface IonItemLongPressAwareProps {
    onClick(): void,
    onLongPress(): void
}

function IonItemLongPressAware({ onClick, onLongPress }: IonItemLongPressAwareProps) {
    const { action, handlers } = useLongPress()

    useEffect(() => {
        if (action === 'click') {
            onClick();
        }

        if (action === 'longpress') {
            onLongPress();
        }
    }, [action]);

    return (
        <IonItem {...handlers} button detail={true}>
            <IonIcon slot={'start'} icon={cameraSharp} />
            <IonLabel>
                <h3>Ajouter photos</h3>
                <p>Prendre ou attacher des photos</p>
            </IonLabel>
        </IonItem>
    )
}

function InterventionPhotos({ id, reference, customerName }: { id: string, reference: string, customerName: string }) {
    const company = useActiveCompany();

    const { mutateAsync: attachFiles, isLoading: uploading } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(id, dto));
    const { data: files, refetch, isLoading } = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const images = (files || []).filter(f => f.contentType.startsWith('image/'));

    const isEmpty = (images && images.length === 0) && !isLoading;

    const { photos, takePhoto } = usePhotoGallery({
        async onCaptured(photos) {
            const dtos: FileToUploadDto[] = photos.map((p, index) => {
                const no = images.length + (index + 1);
                const base64Content = p.base64Content.split(',')[1];

                return {
                    base64Content,
                    filename: p.filename,
                    tag: 'Photos VT',
                    description: `Photos VT ${company.name} ${customerName} ${reference} - ${no}`,
                    contentType: p.contentType,
                }
            });

            await attachFiles(dtos);

            refetch();
        }
    });

    const downloadZip = () => {
        const folderName = `Dossier photos ${customerName} ${company.name}`;

        const zip = new JSZip();

        images.forEach((image, index) => {
            const src = `${image.base64Content}`;

            zip.file(image.filename, src, {base64: true});
        })

        zip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, `${folderName}.zip`);
        });
    }

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Photos</IonLabel>

                <IonButton fill="clear" color={'primary'} disabled={isLoading || isEmpty} onClick={() => downloadZip()}>
                    <IonIcon slot={"start"} md={cloudDownloadSharp} ios={cloudDownloadOutline}/>
                    Télécharger
                </IonButton>
            </IonListHeader>

            <IonItemLongPressAware
                onClick={() => takePhoto()}
                onLongPress={() => takePhoto({ source: CameraSource.Photos })}
            />

            <IonGrid>
                <IonRow>
                    {
                        images.map((image, index) => {
                            const src = `data:${image.contentType};base64,${image.base64Content}`;

                            return (
                                <ImageCol
                                    key={`image-${index}`}
                                    fileId={image.id}
                                    interventionId={id}
                                    source={src}
                                    savedImageDescription={image.description}
                                    defaultSavedImageName={`annoted-${image.filename}`}
                                    onClose={
                                        () => {
                                            refetch(); // Reftech de la liste des images
                                        }
                                    }
                                />
                            )
                        })
                    }
                </IonRow>
            </IonGrid>
        </IonList>
    )
}

interface ImageColProps {
    interventionId: string;
    fileId: string;
    source: string;
    savedImageDescription: string;
    defaultSavedImageName?: string,
    onClose: () => void
}

function ImageCol({ interventionId, fileId, source, savedImageDescription, defaultSavedImageName, onClose }: ImageColProps) {
    const [isOpen, setIsOpen] = useState(false);

    const { isLoading, mutateAsync } = useMutation((ids: string[]) => {
        return unlinkFiles(interventionId, ids);
    });

    const [presentAlert] = useIonAlert();

    const onDelete = () => {
        presentAlert({
            header: 'Confirmation',
            message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                },
                {
                    text: 'Supprimer',
                    role: 'confirm',
                    handler: async () => {
                        await mutateAsync([fileId]);
                        onClose(); // hackeux car mal nommé et simplement utilisé pour refresh
                    },
                },
            ],
        })
    }

    return (
        <>
            <IonCol size="3">
                <ImgDiv>
                    <IonImg className="profilepic padding-bottom" src={source}/>

                    <div className={"buttons"}>
                        <div style={{ marginLeft: 'auto' }}>
                            <IonButton size="small" color={"light"} onClick={() => setIsOpen(true)}>
                                <IonIcon slot="icon-only" ios={createOutline} md={createSharp} style={{ fontSize: '1rem' }} />
                            </IonButton>

                            <IonButton size="small" color={"light"} onClick={onDelete} disabled={isLoading}>
                                <IonIcon slot="icon-only" ios={trashOutline} md={trashSharp} style={{ fontSize: '1rem' }} />
                            </IonButton>
                        </div>
                    </div>
                </ImgDiv>
            </IonCol>

            <ImageEditor
                interventionId={interventionId}
                isOpen={isOpen}
                source={source}
                savedImageDescription={savedImageDescription}
                defaultSavedImageName={defaultSavedImageName}
                onClose={() => {
                    setIsOpen(false);
                    onClose();
                }}
            />
        </>
    )
}

const ImgDiv = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    .buttons {
        display: flex;
    }
`;

interface ImageEditorProps {
    interventionId: string;
    isOpen: boolean;
    source: string;
    savedImageDescription: string,
    defaultSavedImageName?: string,
    onClose: () => void
}

function ImageEditor({ interventionId, isOpen, source, savedImageDescription, defaultSavedImageName, onClose }: ImageEditorProps) {
    const company = useActiveCompany();

    const { mutateAsync: attachFiles, isLoading: uploading } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(interventionId, dto));

    return (
        <IonModal isOpen={isOpen} className={'fullscreen'}>
            <FilerobotImageEditor
                source={source}
                onBeforeSave={() => false}
                onSave={
                    async (image, designState) => {
                        const base64Content = (image.imageBase64 || '').split(',')[1];
                        const defaultFilename = `${interventionId}-${company.name}.${image.extension}`;

                        const dto: FileToUploadDto = {
                            base64Content,
                            filename: image?.fullName || defaultFilename,
                            tag: '[Annotation] Photos VT',
                            description: `[Annotée] ${savedImageDescription}`,
                            contentType: image.mimeType,
                        }

                        await attachFiles([dto]);

                        onClose();
                    }
                }
                onClose={onClose}
                defaultSavedImageName={defaultSavedImageName}
                annotationsCommon={{
                    fill: '#ff0000',
                    stroke: '#ff0000',
                }}
                Text={{ text: 'Commentaires' }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                language={'fr'}
                translations={{
                    save: 'Enregistrer',
                    saveAs: 'Enregistrer sous',
                    back: 'Retour',
                    loading: 'Chargement...',
                    adjustTab: 'Ajuster',
                    annotateTab: 'Annoter',

                    // arrowTool: 'Flèche',
                    // addImage: '+ Ajouter image',
                    // lineTool: 'Ligne',
                    penTool: 'Dessiner',
                    // polygonTool: 'Polygone',
                    // rotateTool: 'Rotation',
                    // textTool: 'Texte',

                    // original: 'Originale',
                    // custom: 'Personnalisé',
                    // square: 'Carré',
                    // landscape: 'Paysage',
                    // portrait: 'Portrait',
                }}
                Crop={{
                    presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: '4:3',
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: '21:9',
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                    ],
                }}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text'
                previewPixelRatio={1}
                savingPixelRatio={1}
            />
        </IonModal>
    )
}

const circleDownIcon = '/assets/icon/circle-down.svg';

interface InterventionCadastreProps {
    id: string,
    reference: string,
    customerName: string,
    proposalId?: string,
    chantier: {
        address: string,
        zipCode: string,
        town: string,
        coords: number[],
    }
}

function InterventionCadastre({ id, reference, customerName, proposalId, chantier }: InterventionCadastreProps) {
    // const { isLoading, data: proposal } = useProposalDetail(proposalId);
    // On ne filtre que les produits Photo dans les lignes du proposal
    // const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0' && p.array_options?.options_product_typology === ProductTypology.PHOTOVOLTAIC);
    // const qtyPanneaux = sum(products.map(p => Number(p.qty))) || 0;
    const qtyPanneaux = 1;

    const {refetch} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IonItem button detail={true} onClick={() => setIsOpen(true)}>
                <IonIcon slot={'start'} md={earthSharp} ios={earthOutline} />
                <IonLabel>
                    <h3>Recherche parcelle cadastrale</h3>
                    <p>Cadastre</p>
                </IonLabel>
            </IonItem>

            <LgModal isOpen={isOpen}>
                <Cadastre
                    interventionId={id}
                    qtyPanneaux={qtyPanneaux}
                    chantier={chantier}
                    reference={reference}
                    customerName={customerName}
                    onDismiss={
                        (data: any, role: string) => {
                            if (role === 'confirm') {
                                refetch();
                            }

                            setIsOpen(false);
                        }
                    }
                />
            </LgModal>
        </>
    )
}

function InterventionLocalizeMe({ id, reference, customerName }: { id: string, reference: string, customerName: string }) {
    const {refetch } = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const [present, dismiss] = useIonModal(LocalizeMe, {
        interventionId: id,
        interventionReference: reference,
        async onDismiss(base64Content: string | null) {
            if (base64Content) {
                refetch();
            }

            dismiss();
        }
    });

    return (
        <IonItem button detail={true} onClick={() => present()}>
            <IonIcon slot={'start'} md={locationSharp} ios={locationOutline} />
            <IonLabel>
                <h3>Préciser la position GPS du chantier</h3>
                <p>Géolocaliser le chantier</p>
            </IonLabel>
        </IonItem>
    )
}

function InterventionPdfDocuments({ id, customerName }: { id: string, customerName: string }) {
    const company = useActiveCompany();

    const {data: files= [], isLoading, refetch} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));
    const isEmpty = (files && files.length === 0) && !isLoading;

    // Tous les autres fichiers sauf les images
    const documents = files.filter(f => !f.contentType.startsWith('image/'));

    const download = async (base64: string, filename: string) => {
        const blob = await blobUtil.base64StringToBlob(base64);
        saveAs(blob, filename);
    }

    const { mutateAsync: attachFiles, isLoading: uploading } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(id, dto));

    const addAttachment: FilePickerProps["onFileChange"] = async ({ base64Content, filename, name, contentType }) => {
        const dto: FileToUploadDto = {
            base64Content: base64Content.split(',')[1],
            filename,
            tag: 'Documents',
            description: name,
            contentType: contentType,
        }

        await attachFiles([dto]);

        refetch();
    }

    const downloadZip = () => {
        const folderName = `Documents ${customerName} ${company.name}`;

        const zip = new JSZip();

        documents.forEach((image, index) => {
            const src = `${image.base64Content}`;

            zip.file(image.filename, src, {base64: true});
        })

        zip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, `${folderName}.zip`);
        });
    }

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Fichiers joints ({ documents.length })</IonLabel>

                <IonButton fill="clear" color={'primary'} disabled={isLoading || isEmpty} onClick={() => downloadZip()}>
                    <IonIcon slot={"start"} md={cloudDownloadSharp} ios={cloudDownloadOutline}/>
                    Télécharger
                </IonButton>
            </IonListHeader>

            <FilePicker
                render={({ pickFile }) => (
                    <IonItem button onClick={() => pickFile()}>
                        <IonIcon slot={"start"} md={addSharp} ios={addOutline} />
                        <IonLabel>
                            <h3>Joindre un nouveau document</h3>
                            <p>Fichiers acceptés pdf, word, excel (taille maximum 5Mo)</p>
                        </IonLabel>
                    </IonItem>
                )}
                accept={docsFileType}
                onFileChange={addAttachment}
            />

            {
                documents?.length === 0 && (
                    <IonItem>
                        <IonLabel>
                            <p>Pas de fichiers joints à cette intervention</p>
                        </IonLabel>
                    </IonItem>
                )
            }

            {
                documents.map(
                    (doc, index) => (
                        <IonItem key={`${doc.filename}-${index}`} button onClick={() => download(doc.base64Content, doc.filename)}>
                            <IonIcon slot={'start'} md={attachSharp} ios={attachOutline} />
                            <IonLabel>
                                <h3>{doc.filename}</h3>
                                <p>{doc.description}</p>
                            </IonLabel>
                            <IonIcon icon={circleDownIcon} slot="end"></IonIcon>
                        </IonItem>
                    )
                )
            }
        </IonList>
    );
}

const LgModal = styled(IonModal)`
    --width: 80%;
    --height: 80%;
`;

function useInterventionDetail<T extends Intervention>(id: string) {
    return useQuery(['intervention', id], ({ queryKey }) => {
        return getIntervention<T>(queryKey[1] as string);
    });
}

function useInvalidateInterventionDetail(id: string) {
    const qc = useQueryClient();

    return () => qc.invalidateQueries({ queryKey: ['intervention', id] });
}

function InterventionButton({ id, type, proposalId  }: { id: string, type: string, proposalId?: string  }) {
    return (
        <>
            {{
                VISITE_TECHNIQUE: <VisiteTechniqueButtons id={id} />,
                POSE: <StartPoseButton id={id} />,
                SAV: <StartSavOrEntretientButton type={'SAV'} />,
                ENTRETIENT: <StartSavOrEntretientButton type={'ENTRETIENT'} />,
                RDVCOM: <RdvComButtons id={id} />
            }[type]}
        </>
    )
}

function VisiteTechniqueButtons({ id }: { id: string }) {
    const { data: intervention } = useInterventionDetail(id);

    if (intervention?.status !== AllInterventionStatus.SCHEDULED) {
        return <></>;
    }

    return (
        <IonRow>
            <IonCol>
                <CancelButton id={id} />
            </IonCol>
            <IonCol>
                <CloseVTButton id={id} />
            </IonCol>
        </IonRow>
    )
}

function CloseVTButton({ id }: { id: string, }) {
    const [presentAlert] = useIonAlert();

    const [presentLoading, dismissLoading] = useIonLoading();

    const [presentToast] = useIonToast();

    const { mutateAsync: closeVisiteTechnique, isLoading: closing } = useMutation(() => closeVisitetechnique(id));

    // Access the client
    const qc = useQueryClient();

    const history = useHistory();

    const onConfirm = async () => {
        presentLoading({
            message: 'Visite technique en cours de clôture',
            onDidDismiss() {
                presentToast({
                    message: 'Visite technique clôturée avec succès',
                    position: 'bottom',
                    duration: 2000,
                });
            }
        });

        await closeVisiteTechnique();
        dismissLoading();

        // Invalidate and refetch
        await qc.invalidateQueries({ queryKey: ['intervention', id] })
        await qc.invalidateQueries({ queryKey: ['intervention', id, 'files'] });

        history.push("/app/Dashboard", { refresh: true });
    };

    const onClick = async () => {
        await presentAlert({
            header: 'Confirmation clôture',
            message: 'Êtes-vous sûr de vouloir clôturer cette visite technique ?',
            buttons: [
                'Annuler',
                {
                    text: 'Confirmer clôture',
                    role: 'confirm',
                    handler: onConfirm,
                }
            ],
        });
    }

    return (
        <IonLoadingButton
            loading={closing}
            expand={"block"}
            onClick={onClick}
            icon={<IonIcon slot={"start"} ios={checkmarkDoneCircleSharp} md={checkmarkCircleOutline} />}
        >
            Clôturer
        </IonLoadingButton>
    )
}

function StartVTButton({ id, typologies, report }: { id: string, typologies: string[], report?: any, }) {
    // Access the client
    const queryClient = useQueryClient();

    const history = useHistory();

    const [present, dismiss] = useIonModal(RapportVisiteTechnique, {
        interventionId: id,
        report,
        // proposalId,
        typologies,
        async onDismiss(reason: 'cancel' | 'submit') {
            if (reason === 'submit') {
                // Invalidate and refetch
                await queryClient.invalidateQueries({ queryKey: ['intervention', id] })
                await queryClient.invalidateQueries({ queryKey: ['intervention', id, 'files'] });

                history.push('/app/Interventions');
            }

            dismiss();
        }
    });

    return (
        <IonButton expand={"block"} onClick={() => present()}>
            Démarrer VT
        </IonButton>
    )
}

function CancelButton({ id }: { id: string }) {
    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading } = useMutation((cancellationReason: string) => cancelVisiteTechnique(id, cancellationReason));

    const [reason, setReason] = useState<string | null | undefined>('Impossibilité technique');
    const isInvalid = (!reason || reason.length < 3);

    async function confirm() {
        try {
            await mutateAsync(reason as string);
            modal.current?.dismiss(reason, 'confirm');
        } catch (e) {
            console.error(e);
        }
    }

    function dismiss() {
        modal.current?.dismiss();
    }

    return (
        <>
            <IonButton id="open-cancel-modal" expand="block" fill={'outline'} color={'danger'}>
                <IonIcon slot={'start'} md={warningSharp} ios={warningOutline}></IonIcon>
                Annuler VT
            </IonButton>

            <IonModal ref={modal} trigger="open-cancel-modal">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={dismiss}>Fermer</IonButton>
                        </IonButtons>
                        <IonTitle>Annulation VT</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p className={'ion-text-center'}>La visite technique sera annulée après confirmation</p>
                    <IonItem>
                        <IonLabel position="stacked">Raison de l'annulation</IonLabel>
                        <IonTextarea value={reason} rows={5} onIonChange={e => setReason(e.detail.value)} placeholder="Raison de l'annulation" />
                    </IonItem>
                </IonContent>

                <IonFooter>
                    <IonButton expand={'block'} color={'danger'} onClick={confirm} disabled={isInvalid}>
                        Confirmer annulation
                    </IonButton>
                </IonFooter>
            </IonModal>
        </>
    )
}

function ArchiveButton({ id }: { id: string }) {
    const [presentAlert] = useIonAlert();

    const { mutateAsync: archive, isLoading } = useMutation(() => setInterventionStatus(id, AllInterventionStatus.ARCHIVED));

    const invalidateInterventionDetail = useInvalidateInterventionDetail(id);

    const history = useHistory();

    const confirm = () => {
        presentAlert({
            header: 'Confirmation',
            message: 'Êtes-vous sûr de vouloir archiver cette intervention ?',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                },
                {
                    text: 'Archiver',
                    role: 'confirm',
                    handler: async () => {
                        await archive();
                        await invalidateInterventionDetail();
                        history.push('/app/Dashboard', { refresh: true });
                    },
                },
            ],
        })
    }

    return (
        <IonLoadingButton
            loading={isLoading}
            onClick={confirm}
            color={"danger"}
            icon={<IonIcon slot={'start'} md={trashBinSharp} ios={trashBinOutline} />}
        >
            Archiver
        </IonLoadingButton>
    )
}

const poseStatusLabelMap: any = {
    idle: 'Démarrer',
    started: 'Clôturer',
}

const poseStatusMap: any = {
    idle: 'started',
    started: 'finalized',
}

function StartPoseButton({ id }: { id: string }) {
    const { refetch } = useInterventionDetail(id);
    const [status, setStatus] = useState<string>('idle');

    const [presentEtatLieux, dismissEtatLieux] = useIonModal(EtatLieux, {
        onDismiss() {
            dismissEtatLieux();
        }
    });

    const [presentCr, dismissCr] = useIonModal(CompteRenduInstallation, {
        id,
        onDismiss() {
            refetch();
            dismissCr();
        }
    });


    const label = poseStatusLabelMap[status];

    const handleStatus = () => {
        const st = poseStatusMap[status];
        setStatus(st);

        if (st === 'started') {
            presentEtatLieux();
        }

        if (st === 'finalized') {
            presentCr();
        }
    }

    return (
        label && (
            <IonButton expand={"block"} onClick={() => handleStatus()}>
                {label}
            </IonButton>
        )
    )
}

function PoseButtons({ type }: { type: string }) {
    const [pause, setPause] = useState<boolean>(false);

    if (type !== 'POSE') {
        return <></>;
    }

    return (
        <IonButtons slot="end">
            <IonButton onClick={() => setPause(prev => !prev)}>
                <IonIcon slot="start" icon={!pause ? pauseSharp : playSharp}></IonIcon>
                {!pause ? 'Pause' : 'Reprendre'}
            </IonButton>
        </IonButtons>
    )
}

const statusLabelMap: any = {
    idle: 'Arrivé',
    arrived: 'Remplir Compte rendu',
    compteRendu: 'Départ',
}

const statusMap: any = {
    idle: 'arrived',
    arrived: 'compteRendu',
    compteRendu: 'departure',
}

function StartSavOrEntretientButton({ type }: { type: string }) {
    const [status, setStatus] = useState<string>('idle');

    const label = statusLabelMap[status];

    const [present, dismiss] = useIonModal(CompteRenduSavAndEntretient, {
        type,
        onDismiss() {
            dismiss();
        }
    });

    const handleStatus = () => {
        const st = statusMap[status];
        setStatus(st);

        if (st === 'compteRendu') {
            present();
        }
    }

    return (
        label && (
            <IonButton expand={"block"} onClick={() => handleStatus()}>
                {label}
            </IonButton>
        )
    )
}

function RdvComButtons({ id }: { id: string }) {
    const { data: intervention } = useInterventionDetail<RdvCom>(id);

    const allowedStatuses = [
        AllInterventionStatus.SCHEDULED,
        AllInterventionStatus.ON_THE_WAY,
        AllInterventionStatus.ARRIVED,
    ];

    if (!(intervention?.status && allowedStatuses.includes(intervention?.status))) {
        return <></>;
    }

    return (
        <IonRow>
            <IonCol>
                <CancelInterventionButton id={id} />
            </IonCol>

            <IonCol>
                <CreateVisiteTechniqueButton {...intervention!} />
            </IonCol>
        </IonRow>
    )
}

function CancelInterventionButton({ id }: { id: string }) {
    const [isOpen, setIsOpen] = useState(false);
    const { refetch } = useInterventionDetail(id);

    return (
        <>
            <IonButton expand="block" fill={'outline'} color={'danger'} onClick={() => setIsOpen(true)}>
                <IonIcon slot={'start'} md={warningSharp} ios={warningOutline}></IonIcon>
                Annuler
            </IonButton>

            <CancelInterventionModal
                interventionId={id}
                isOpen={isOpen}
                handleClose={
                    () => {
                        setIsOpen(false);
                        refetch();
                    }
                }
            />
        </>
    )
}

function OnTheWayButton({ id  }: { id: string }) {
    const { mutateAsync, isLoading } = useMutation(() => agentIsOnTheWayToIntervention(id));
    const { refetch } = useInterventionDetail(id);

    const handleClick = async () => {
        await mutateAsync();
        await refetch();
    }

    return (
        <IonButton expand={"block"} color={"success"} onClick={handleClick} disabled={isLoading}>
            En route vers le client
        </IonButton>
    )
}

function ArrivedButton({ id  }: { id: string }) {
    const { mutateAsync, isLoading } = useMutation(() => agentArrivedAtTheInterventionPlace(id));
    const { refetch } = useInterventionDetail(id);

    const handleClick = async () => {
        await mutateAsync();
        await refetch();
    }

    return (
        <IonButton expand={"block"} onClick={handleClick} disabled={isLoading}>
            Arrivé chez le client
        </IonButton>
    )
}

function CreateVisiteTechniqueButton(intervention: RdvCom) {
    const { id, type, customer, place, entity, technician, date, typeData, } = intervention;
    const { mutateAsync: createVT, isLoading } = useMutation((dto: CreateVisiteTechinqueDto) => createVisiteTechnique(dto));

    const router = useIonRouter();

    // check si le rdv com a une vt
    const hasVT = !!intervention.typeData.visiteTechnique;

    const onConfirm = async () => {
        const dto: CreateVisiteTechinqueDto = {
            productTypologies: [ProductTypology.PHOTOVOLTAIC],
            customer,
            place: {
                address: place.address,
                zipCode: place.zipCode,
                town: place.town,
                coords: place.coords,
            },
            duration: 1,
            entity,
            technician,
            date,
            source: id,
            sourceType: type,
            report: {
                informations: {
                    dateVisite: date,
                    nomSite: customer?.companyName,
                    nomContact: `${customer.firstName || ''} ${customer.lastName || ''}`.trim(),
                    intitulePoste: "",
                    email: customer?.email,
                    tel: customer?.phone,
                },
                suiviCommercial: {
                    datePremiereVisite: date,
                },
                caracteristiquesBatiment: {
                    elementsCouverture: {
                        typeToiture: typeData.batiment.typeToiture,
                        natureCouverture: typeData.batiment.natureCouverture,
                    }
                }
            }
        }

        const i = await createVT(dto);
        await router.push(`../Interventions/${i.id}`, 'forward')
    };

    const [presentAlert] = useIonAlert();

    const confirmCreation = () => {
        presentAlert({
            header: 'Confirmation',
            message: 'Êtes-vous sûr de vouloir démarrer la Visite Technique liée à ce RDV ?',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                },
                {
                    text: 'Démarrer VT',
                    role: 'confirm',
                    handler: () => {
                        onConfirm();
                    },
                },
            ],
        })
    }

    return (
        <IonLoadingButton
            loading={isLoading}
            disabled={hasVT}
            expand={'full'}
            color={'primary'}
            onClick={confirmCreation}
            icon={<IonIcon slot={'start'} md={playSharp} ios={playOutline} />}
        >
            Démarrer Visite Technique
        </IonLoadingButton>
    )
}

function DepartureButton({ id  }: { id: string }) {
    const { mutateAsync: leave, isLoading: leaving } = useMutation(() => agentIsLeavingInterventionPlace(id));
    const { mutateAsync: complete, isLoading: completing } = useMutation(() => closeRdvCom(id));
    const { refetch } = useInterventionDetail(id);

    const history = useHistory();

    const handleClick = async () => {
        await leave();
        await complete();
        history.push('/app/Interventions');
    }

    return (
        <IonButton expand={"block"} onClick={handleClick} disabled={leaving || completing}>
            Départ
        </IonButton>
    )
}
