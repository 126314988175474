import {TextField, TextFieldProps} from "@mui/material";
import {usePlacesWidget} from "react-google-autocomplete";
import {mapKeys} from 'lodash';

const API_KEY = 'AIzaSyBTiAjXJXGUBJ8ylsAdPIpfIdIHt3qSwhg';

interface PlaceResult {
    country: string;
    city: string;
    postalCode: string;
    route: string;
    street: string;
    lat: number;
    lng: number;
}

type AddressAutocompleteProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
    value?: string;
    onChange?(p: PlaceResult): void;
    error?: boolean;
}

export default function AddressAutocomplete({ value, onChange, error }: AddressAutocompleteProps) {
    const { ref } = usePlacesWidget({
        apiKey: API_KEY,
        onPlaceSelected: (place: any) => {
            // console.log(place);

            if (!place.place_id || place.geometry === undefined || place.geometry === null) {
                console.error('result is not valid');
                return;
            }

            const mappedResponse: any = extractAddressComponents(place.address_components || []);

            return onChange && onChange({
                ...mappedResponse,
                lat: place.geometry?.location?.lat(),
                lng: place.geometry?.location?.lng()
            });
        },
        inputAutocompleteValue: "country",
        options: {
            componentRestrictions: { country: 'fr' },
            types: ['address'],
        },
    });

    return (
        <TextField variant="outlined" size="small" value={value} inputRef={ref} error={error} />
    );

    // const {
    //     placePredictions,
    //     getPlacePredictions,
    //     isPlacePredictionsLoading,
    // } = usePlacesAutocompleteService({
    //     apiKey: API_KEY,
    //     options: {
    //         componentRestrictions: { country: 'fr' },
    //         types: ['address'],
    //     }
    // });


    // return (
    //     <Autocomplete
    //         disablePortal
    //         loading={isPlacePredictionsLoading}
    //         loadingText={"Recherche en cours"}
    //         options={placePredictions}
    //         getOptionLabel={(option) => option.description}
    //         sx={{ width: 300 }}
    //         autoComplete
    //         includeInputInList
    //         // value={value}
    //         onChange={(event: any, newValue: any | null) => {
    //             console.log(newValue);
    //         }}
    //         renderInput={
    //             (params) => {
    //                 return (
    //                     <TextField
    //                         {...params}
    //                         {...InputProps}
    //                         variant="outlined"
    //                         onChange={
    //                             (e) => {
    //                                 getPlacePredictions({ input: e.target.value });
    //                             }
    //                         }
    //                     />
    //                 )
    //             }
    //         }
    //     />
    // );
}

const componentsMapping: any = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    // administrative_area_level_1: 'short_name',
}

const addressComponentsMapping: any = {
    street_number: 'street',
    route: 'route',
    locality: 'city',
    country: 'country',
    postal_code: 'postalCode',
}

const extractAddressComponents = (addressComponents: any[]) => {
    const result: any = {};

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (let i = 0; i < addressComponents.length; i++) {
        const addressType = addressComponents[i].types[0];
        if (componentsMapping[addressType]) {
            result[addressType] = addressComponents[i][componentsMapping[addressType]];
        }
    }

    return mapKeys(result, (value, key) => addressComponentsMapping[key]);
}
