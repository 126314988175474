import {isNil, omitBy} from "lodash";
import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {AllInterventionStatus, InterventionType} from "../../models/intervention.model";

export type CountInterventionsByStatus = {
    type?: InterventionType | string;
    technicianId?: string;
}

export async function countInterventionsByStatus(payload: CountInterventionsByStatus = {}) {
    const dto = omitBy(payload, isNil);

    const {data} = await interventionHttp.post<Record<AllInterventionStatus | string, number>>(Endpoint.interventionCountByStatus, dto);

    return data;
}
