import {
    IonButton,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTextarea,
    IonThumbnail,
    IonTitle,
    IonToolbar,
    useIonLoading,
    useIonToast
} from "@ionic/react";
import React, {useState} from "react";
import {useSignaturePad} from "../SignaturePad/useSignaturePad";
import {useMutation} from "@tanstack/react-query";
import {closePose} from "../../calls/Interventions/closePose";

export default function CompteRenduInstallation({ id, onDismiss }: any) {
    const [present, dismiss] = useIonLoading();

    const [presentToast] = useIonToast();

    const { mutateAsync, isLoading } = useMutation(() => closePose(id));

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Compte rendu d’installation</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonItem>
                    <IonLabel position={"stacked"}>Matériel installé</IonLabel>
                    <IonInput placeholder="Matériel installé" />
                </IonItem>

                <IonItem>
                    <IonLabel position={"stacked"}>Numéro de série</IonLabel>
                    <IonInput placeholder="Numéro de série" />
                </IonItem>

                <IonItem>
                    <IonLabel position={"stacked"}>Réglages et essai de fonctionnement</IonLabel>
                    <IonTextarea rows={4} placeholder="Réglages et essai de fonctionnement"></IonTextarea>
                </IonItem>

                <IonList>
                    <IonListHeader>
                        <IonLabel>Questionnaires</IonLabel>
                    </IonListHeader>

                    {
                        questions.map(
                            q => {
                                return (
                                    <IonItemGroup key={q.label} class="ion-text-wrap">
                                        <IonItemDivider>
                                            <IonLabel>
                                                {q.label}
                                            </IonLabel>
                                        </IonItemDivider>

                                        <IonRadioGroup>
                                            {
                                                q.answers.map(
                                                    a => {
                                                        return (
                                                            <IonItem key={`${q.label}-${a}`} class="ion-text-wrap">
                                                                <IonLabel>{a}</IonLabel>
                                                                <IonRadio slot="end" value={a}/>
                                                            </IonItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </IonRadioGroup>
                                    </IonItemGroup>
                                )
                            }
                        )
                    }
                </IonList>

                <IonItem>
                    <IonLabel position={"stacked"}>Réserves éventuelles</IonLabel>
                    <IonTextarea rows={4} placeholder="Réserves éventuelles"></IonTextarea>
                </IonItem>

                <IonItem>
                    <IonLabel position={"stacked"}>Appréciations du client</IonLabel>
                    <IonTextarea rows={4} placeholder="Appréciations du client"></IonTextarea>
                </IonItem>

                <IonRow>
                    <SignatureCol label={'Signature du client'} name={'customerSignature'} />

                    <SignatureCol label={'Signature du technicien'} name={'technicianSignature'} />
                </IonRow>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={"block"} fill="clear" onClick={() => onDismiss && onDismiss()}>Annuler</IonButton>
                    </IonCol>

                    <IonCol>
                        <IonButton
                            expand={"block"}
                            onClick={async () => {
                                present({
                                    message: 'Sauvegarde état des lieux en cours',
                                    duration: 3000
                                });

                                await mutateAsync();

                                await presentToast({
                                    message: 'État des lieux sauvegardé avec succès',
                                    duration: 1500,
                                    position: 'bottom'
                                });

                                dismiss(); // Dismiss loading

                                onDismiss!(); // Dismiss le modal
                            }}>Enregistrer</IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}

const questions = [
    { label: 'Ponctualité', answers: ['Bon', 'Mauvais'] },
    { label: 'Emplacement de l’unité extérieure', answers: ['Bon', 'Mauvais'] },
    { label: 'Emplacement des unités intérieures', answers: ['Bon', 'Mauvais'] },
    { label: 'Passage tuyaux', answers: ['Oui', 'Non'] },
    {
        label: 'Explication du fonctionnement Dégivrage, condensats, télécommande',
        answers: ['Bon', 'Mauvais']
    },
    { label:  'Etat de l’installation électrique', answers: ['Bon', 'Moyen', 'Mauvais'] },
    { label:  'Propreté et finitions', answers: ['Bon', 'Mauvais'] },
]

const signatureIcon = '/assets/icon/signature.svg';

function SignatureCol({ name, label }: { name: string, label: string }) {
    // const {
    //     field: { value: signature, onChange },
    // } = useController({
    //     control,
    //     name,
    //     defaultValue: "",
    // });

    const [signature, setSignature] = useState('');

    const persentSignaturePad = useSignaturePad({
        onDismiss(data, role) {
            if (role !== 'confirm') {
                return;
            }

            setSignature(data as any);
        }
    });

    return (
        <IonCol className={"ion-no-padding"}>
            <IonItem button detail={true} onClick={() => persentSignaturePad()}>
                {
                    signature ? (
                        <IonThumbnail slot="start">
                            <img alt="Signature" src={signature} />
                        </IonThumbnail>
                    ) : (
                        <IonIcon icon={signatureIcon} slot="start"></IonIcon>
                    )
                }
                <IonLabel>
                    <h3>{label}</h3>
                </IonLabel>
            </IonItem>
        </IonCol>
    )
}
