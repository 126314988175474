import axios from "axios";

const url = 'https://apicarto.ign.fr/api/cadastre/parcelle';

interface GetParcelleCommunePayload {
    codeInsee: string;
    section: string,
    limit?: number;
    start?: number;
}

interface GetParcelleCommuneReturn {
    bbox: [number, number, number, number];
    crs: {type: "name", properties: {name: "urn:ogc:def:crs:EPSG::4326"}};
    features: any[];
    links: [{ title: string, type: "application/json", rel: string, href: string }];
    numberMatched: number;
    numberReturned: number;
    timeStamp: string;
    totalFeatures: number;
    type: "FeatureCollection";
}

export async function getParcelleCommune({ codeInsee, section, start = 0, limit = 1000 } :GetParcelleCommunePayload) {
    const { data } = await axios.get<GetParcelleCommuneReturn>(url,{
        params : {
            code_insee: codeInsee,
            section,
            _start: start,
            _limit: limit,
        },
    });

    return {
        data,
        pagination: {
            start,
            limit,
        }
    };
}
