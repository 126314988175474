import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Intervention} from "../../models/intervention.model";

export async function editReport(id: string, report: any = {}) {
    const url = Endpoint.interventionEditReport.replace(':id', id);

    const {data} = await interventionHttp.post<Intervention>(url, report);

    return data;
}
