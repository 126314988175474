import {useInterventionsQuery} from "../components/Interventions/useInterventionQuery";
import {
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonSkeletonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {AllInterventionStatus, interventionUI} from "../models/intervention.model";
import {DateTime} from "luxon";
import {useInterventionUI} from "../components/Shared/useInterventionUI";
import {useUser} from "../components/Auth/auth.store";
import {useEffect} from "react";
import {useLocation} from "react-router";

export default function DashboardPage() {
    const user = useUser();

    const location = useLocation<{ refresh: true }>();

    const { data, isLoading, refetch } = useInterventionsQuery({
        technicianId: user.id,
        statuses: [
            AllInterventionStatus.TO_SCHEDULE,
            AllInterventionStatus.SCHEDULED,
            AllInterventionStatus.PARTIALLY_COMPLETED,
            AllInterventionStatus.COMPLETED,
            AllInterventionStatus.ON_THE_WAY,
            AllInterventionStatus.ARRIVED,
            AllInterventionStatus.ETUDE_TERMINEE,
            AllInterventionStatus.ETUDE_ANNULEE,
        ],
        limit: 50,
        sort: '-updatedAt',
    });

    useEffect(() => {
        if (!location.state?.refresh) {
            return;
        }

        refetch();
    }, [location.state]);

    const items = data?.docs || [];

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Dashboard</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Dashboard</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>Avancements</IonCardSubtitle>
                        <IonCardTitle>Interventions récentes</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonList>
                            {
                                isLoading && (
                                    [1, 2, 3, 4].map(
                                        (v, index) => (
                                            <IonItem key={'lastproposals-skeleton' + index}>
                                                <IonLabel>
                                                    <h3>
                                                        <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                    </h3>
                                                    <p>
                                                        <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                    </p>
                                                </IonLabel>
                                            </IonItem>
                                        )
                                    )
                                )
                            }

                            {
                                !isLoading && items.length === 0 && (
                                    <IonItem>
                                        <IonLabel>Aucune intervention récente</IonLabel>
                                    </IonItem>
                                )
                            }

                            {
                                !isLoading && items.length > 0 && (
                                    <>
                                        {
                                            (items).map(
                                                (a, index) => {
                                                    const status = a.status;
                                                    const label = interventionUI.statusTranslation[status] || status;
                                                    const statusColor = interventionUI.statusColorMapping[status] || 'dark';

                                                    const toFormat= 'dd/MM/yyyy HH:mm';
                                                    const date = a.createdAt ? DateTime.fromISO(a.createdAt).toFormat(toFormat) : '-- -- ----';

                                                    return (
                                                        <IonItem button detail={true} key={`avancements-${index}`} lines={"none"} routerLink={`/app/Interventions/${a.id}`} routerDirection={"forward"}>
                                                            <IonLabel>
                                                                <h2>{a?.customer?.name}</h2>
                                                                <p>Créé le {date}</p>
                                                            </IonLabel>

                                                            <InterventionTypeChip type={a.type} />

                                                            <IonChip slot={"end"} color="primary">
                                                                {a.reference}
                                                            </IonChip>

                                                            <IonChip slot={"end"} color={statusColor}>{label}</IonChip>
                                                        </IonItem>
                                                    )
                                                }
                                            )
                                        }
                                    </>
                                )
                            }
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    )
}

function InterventionTypeChip({ type }: { type: string }) {
    const { label, chipColor } = useInterventionUI({ type });

    return (
        <IonChip color={chipColor} slot={"end"}>{label}</IonChip>
    )
}
