import InterventionWorkflowStepper from "../components/Interventions/InterventionWorkflowStepper";
import {InterventionType} from "../models/intervention.model";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import InterventionLabel from "../components/Shared/InterventionLabel";
import React from "react";

export default function CreateIntervention() {
    const type = InterventionType.RDVCOM;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>
                        <InterventionLabel type={type} />
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <InterventionWorkflowStepper type={type} />
            </IonContent>
        </IonPage>
    )
}
