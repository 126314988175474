import {interventionHttp} from "../../http";
import {ProductTypology} from "../../models/intervention.model";
import {Endpoint} from "../endpoint.enum";

interface InstallationProcedureItem {
    name: string;
    url: string;
    size: number;
    tag: string;
}

export async function listInstallationProcedures() {
    const {data} = await interventionHttp.get<Record<ProductTypology, InstallationProcedureItem[]>>(Endpoint.installationProcedures);

    return data;
}
