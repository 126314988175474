import "./Wordmark.css";
import {IonText} from "@ionic/react";

export default function Wordmark({ size }: { size?: 'md' | 'lg' }) {
    return (
        <>
            <IonText color={"amdp-primary"} style={{ fontSize: size === 'lg' ? '3em' : '' }}>
                <span className={"wordmark"}>{"amdp"}</span>
            </IonText>

            <br />

            <IonText color={"amdp-primary"} style={{ fontSize: size === 'lg' ? '3em' : '' }}>
                Interventions
            </IonText>
        </>
    )
}
