import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {Pose} from "../../models/intervention.model";

export async function closePose(id: string) {
    const url = Endpoint.closePose.replace(':id', id);

    const {data} = await interventionHttp.post<Pose>(url, {});

    return data;
}
