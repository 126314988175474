import {useIonModal} from "@ionic/react";
import SignaturePad from "./SignaturePad";

type UseSignaturePadProps = {
    onDismiss?: (data?: string, role?: 'cancel' | 'confirm') => void
}

export function useSignaturePad({onDismiss}: UseSignaturePadProps) {
    const [present, dismiss] = useIonModal(SignaturePad, {
        onDismiss: (data: string, role: 'cancel' | 'confirm') => {
            onDismiss && onDismiss(data, role);
            dismiss(data, role);
        },
    });

    return present;
}