import React, {useRef} from "react";

export const docsFileType = '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const imagesFileType = 'image/png,image/jpeg,image/jpg';
export const allAcceptedFileType = `${imagesFileType},${docsFileType}`;

export interface FilePickerProps {
    render: (opts: { pickFile(): void }) => React.ReactNode;
    accept?: string;
    tooltip?: string;
    onFileChange?(result: {
        filename: string, // Nom complet du fichier extension comprise
        name: string, // Nom du fichier sans extension
        contentType: string,
        base64Content: string
    }): void;
}

export default function FilePicker({render, accept, tooltip, onFileChange}: FilePickerProps) {
    const ref = useRef();

    const pickFile = () => {
        (ref.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const name = filename.split('.').slice(0, -1).join('.') || filename;
        const contentType = file.type;
        const base64Content = await blobToBase64(file);

        onFileChange && onFileChange({
            filename,
            name,
            contentType,
            base64Content,
        });
    };

    return (
        <>
            {render({ pickFile })}

            <input
                ref={ref as any}
                accept={accept || allAcceptedFileType}
                type="file"
                style={{display: "none"}}
                onChange={handleFileChange}
            />
        </>
    )
}

function blobToBase64(blob: Blob) {
    return new Promise<string>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}
