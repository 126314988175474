import React from "react";
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Map from './pages/Map';
import Interventions from './pages/Interventions';
import InterventionDetail from "./pages/InterventionDetail";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './app.css';
import LoginPage from "./pages/Login";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import Account from "./pages/Account";
import InstallationProcedures from "./pages/InstallationProcedures";
import Menu from "./components/Menu";
import DashboardPage from "./pages/DashboardPage";
import ProposalDetail from "./pages/ProposalDetail";
import CreateIntervention from "./pages/CreateIntervention";

setupIonicReact();

const App: React.FC = () => (
    <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
                <Route path="/" exact={true}>
                    <Redirect to="/app" />
                </Route>

                <Route path="/Login" exact={true}>
                    <LoginPage />
                </Route>

                <ProtectedRoute path="/app">
                    <PageNestedRoutes />
                </ProtectedRoute>
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>
);

export default App;

function PageNestedRoutes() {
    const match = useRouteMatch();

    const url = (path: string) => `${match.path}/${path}`;

    return (
        // Min width 1400 pour cacher sur la tablette en paysage
        <IonSplitPane when="(min-width: 1400px)"  contentId="main">
            <Menu />

            <IonRouterOutlet id="main">
                <ProtectedRoute path={match.path} exact={true}>
                    <Redirect to={url("Dashboard")} />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Dashboard")}>
                    <DashboardPage />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Map")}>
                    <Map />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Interventions")}>
                    <Interventions />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Interventions/:id")}>
                    <InterventionDetail />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("Interventions/New")}`} exact={true}>
                    <CreateIntervention />
                </ProtectedRoute>

                <ProtectedRoute path={`${url("Proposal/:id")}`} exact={true}>
                    <ProposalDetail />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("InstallationProcedures")}>
                    <InstallationProcedures />
                </ProtectedRoute>

                <ProtectedRoute exact path={url("Account")}>
                    <Account />
                </ProtectedRoute>
            </IonRouterOutlet>
        </IonSplitPane>
    )
}
