import 'leaflet/dist/leaflet.css'
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter
} from '@ionic/react';
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import styled from "@emotion/styled";
import {useUser} from "../components/Auth/auth.store";
import {useInterventionsQuery} from "../components/Interventions/useInterventionQuery";
import {AllInterventionStatus, interventionUI} from "../models/intervention.model";
import {DateTime} from "luxon";
import {useEffect} from "react";

const Map: React.FC = () => {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });

    const markers = useMarkers();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>Carte</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <StyledMapContainer center={[44.837789, -0.57918]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; AMDP'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />

                    {
                        markers.map(
                            m => {
                                const [lng, lat] = m.place!.coords! as [number,  number];

                                return (
                                    <Marker key={m.id} position={[lat,  lng]}>
                                        <Popup>
                                            <h6>
                                                {m.customer.name}<br />
                                                <small>Prévue le {m.plannedDate}</small>
                                            </h6>
                                            <p style={{ margin: 0 }}><b>Intervention:</b> {m.interventionText}</p>
                                            <p style={{ margin: 0 }}><b>Adresse:</b> {m.addressText}</p>
                                            <p style={{ margin: 0 }}><b>Technicien:</b> {m.technician?.name || 'N/A'}</p>
                                            <p style={{ margin: 0 }}><b>Référence:</b> {m.reference || 'N/A'}</p>
                                        </Popup>
                                    </Marker>
                                )
                            }
                        )
                    }

                    <FitMarkersBounds />
                </StyledMapContainer>
            </IonContent>
        </IonPage>
    );
};

export default Map;

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
`;

function useMarkers() {
    const user = useUser();

    const { data, isLoading } = useInterventionsQuery({
        technicianId: user.id,
        status: AllInterventionStatus.SCHEDULED,
    });

    const interventions = data?.docs || [];

    return interventions.map(
        c => {
            const plannedDate = DateTime.fromISO(c?.date || '',{zone:"Europe/Paris"}).setLocale('fr');
            const addressText = [
                c.place.address,
                `${c.place.zipCode || ''} ${c.place.town || ''}`.trim(),
            ].filter(i => !!i).join(', ');
            const technician = c.technician;
            const interventionText = (interventionUI.typeTranslation as any)[c.type] || 'N/A';

            return {
                id: c.id,
                reference: c.reference,
                customer: c.customer,
                plannedDate: plannedDate.toLocaleString(DateTime.DATETIME_MED),
                interventionText,
                addressText,
                technician,
                place: c.place,
            }
        }
    )
}

function FitMarkersBounds() {
    const user = useUser();

    const { data, isLoading } = useInterventionsQuery({
        technicianId: user.id,
        status: AllInterventionStatus.SCHEDULED,
    });

    const interventions = data?.docs || [];

    const map = useMap();

    useEffect(() => {
        if (interventions.length === 0) {
            return;
        }

        const allPositions: Array<[number, number]> = interventions.map(c => {
            const [lng, lat] = c.place.coords as [number, number];
            return [lat, lng];
        });

        map.fitBounds(allPositions, { maxZoom: 10 });
    }, [interventions]);

    return null;
}
