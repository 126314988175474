import {AllInterventionStatus, interventionUI} from "../../models/intervention.model";
import {IonChip} from "@ionic/react";

interface InterventionStatusChipProps {
    status: AllInterventionStatus | string;
}

export default function InterventionStatusChip({ status }: InterventionStatusChipProps) {
    const label = interventionUI.statusTranslation[status as AllInterventionStatus] || status;
    const statusColor = interventionUI.statusColorMapping[status as AllInterventionStatus] || 'dark';

    return (
        <IonChip slot={"end"} color={statusColor}>{label}</IonChip>
    )
}
