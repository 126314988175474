import {
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonTitle,
    IonToast,
    IonToolbar,
} from '@ionic/react';
import {useEffect, useRef, useState} from "react";
import {arrowForward, checkmarkCircleSharp, filterOutline, filterSharp, shieldCheckmarkOutline} from "ionicons/icons";
import styled from "@emotion/styled";
import {useInterventionUI} from "../components/Shared/useInterventionUI";
import {AllInterventionStatus, Intervention, InterventionType, interventionUI} from "../models/intervention.model";
import {useUser} from "../components/Auth/auth.store";
import {DateTime} from "luxon";
import {FormProvider, useForm} from "react-hook-form";
import GenericDatetimePicker from "../components/RapportVisiteTechnique/Form/Controls/Generic/GenericDatetimePicker";
import GenericSelect from "../components/RapportVisiteTechnique/Form/Controls/Generic/GenericSelect";
import GenericTextField from "../components/RapportVisiteTechnique/Form/Controls/Generic/GenericTextField";
import {useInterventionsFilterStore} from "../components/Interventions/useInterventionsFilterStore";
import {useInterventionsInfiniteQuery} from "../components/Interventions/useInterventionsInfiniteQuery";
import {isEmpty, isNil, omitBy} from "lodash";
import InterventionStatusChip from "../components/Interventions/InterventionStatusChip";
import {useLocation} from "react-router";
import {useMutation} from "@tanstack/react-query";
import {deleteIntervention} from "../calls/Interventions/deleteIntervention";

const Interventions: React.FC = () => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

    const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);

    const page = useRef(null);

    useEffect(() => {
        setPresentingElement(page.current);
    }, []);

    const closeFilterModal = () => setIsFilterModalOpen(false);

    return (
        <IonPage ref={page}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>Interventions</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill={"clear"} color={"primary"} onClick={() => setIsFilterModalOpen(true)}>
                            <IonIcon slot={"start"} md={filterSharp} ios={filterOutline} />
                            Filtres
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <AllInterventions />

                <IonModal isOpen={isFilterModalOpen} presentingElement={presentingElement!} onWillDismiss={closeFilterModal} keepContentsMounted={false}>
                    <FilterModalContent onClose={closeFilterModal} />
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default Interventions;

function useInterventions() {
    const user = useUser();

    const { search } = useLocation();
    const qs = new URLSearchParams(search);
    const defaultStatuses = (qs.get('status') || '').split(',').filter(s => !!s);

    const { filter, pagination} = useInterventionsFilterStore();

    const query = useInterventionsInfiniteQuery({
        ...filter,
        ...pagination,
        technicianId: user.id,
        ...(
            defaultStatuses.length > 0 ? ({
                statuses: defaultStatuses
            }) : ({
                status: filter.status
            })
        )
    });

    return query;
}

function AllInterventions() {
    const { isLoading, isError, data, hasNextPage, fetchNextPage } = useInterventions();

    const pages = (data?.pages || []);

    if (!isLoading && !isError && pages.map(p => p.docs.length).every(v => v === 0)) {
        return (
            <NoInterventions />
        )
    }

    return (
        <>
            {
                isLoading && (
                    <InterventionsSkeleton />
                )
            }

            <IonList inset={true}>
                {
                    pages.map(
                        page => (
                            page.docs.map(
                                (intervention, index) => {
                                    return (
                                        (intervention.status !== AllInterventionStatus.ARCHIVED)
                                            ? <InterventionItem key={`intervention-${index}`} intervention={intervention} />
                                            : <ArchivedInterventionItem key={`archived-intervention-${index}`} intervention={intervention} />
                                    )
                                }
                            )
                        )
                    )
                }
            </IonList>

            <IonInfiniteScroll
                onIonInfinite={async (e) => {
                    if (hasNextPage) {
                        await fetchNextPage();
                    }

                    e.target.complete();
                }}
            >
                <IonInfiniteScrollContent />
            </IonInfiniteScroll>
        </>
    )
}

function InterventionsSkeleton() {
    return (
        <IonList>
            {
                Array(10)
                    .fill('')
                    .map((_, i) => (
                        <IonItem key={`skeleton-${i}`}>
                            <IonLabel>
                                <h3>
                                    <IonSkeletonText animated={true} style={{ width: '60%' }}/>
                                </h3>
                                <p>
                                    <IonSkeletonText animated={true} style={{ width: '80%' }}/>
                                </p>
                                <p>
                                    <IonSkeletonText animated={true} style={{ width: '30%' }}/>
                                </p>
                                <p>
                                    <IonSkeletonText animated={true} style={{ width: '20%' }}/>
                                </p>
                            </IonLabel>
                        </IonItem>
                    ))
            }
        </IonList>
    )
}

function NoInterventions() {
    return (
        <IonGrid style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <IonRow>
                <IonCol>
                    <div className="ion-text-center">
                        <h3>Pas d'interventions</h3>
                        <p>Aucune intervention trouvée. Vérifiez les filtres.</p>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

interface InterventionItemProps {
    intervention: Intervention;
}

function InterventionItem({ intervention }: InterventionItemProps) {
    const { id, date, customer, reference, status, place: { address, zipCode, town, }, type, typeData } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    const { label, chipColor } = useInterventionUI({ type });

    return (
        <IonItem routerLink={`/app/Interventions/${id}`} button detail={true}>
            <IonLabel>
                <h3>{customer.name}</h3>
                <p>{addressText}</p>

                {
                    type === InterventionType.POSE && (
                        <StyledRange>
                            <span>{formatPoseDate(typeData.theoricalStartDate)}</span>
                            <IonIcon icon={arrowForward} />
                            <span>{formatPoseDate(typeData.theoricalEndDate)}</span>
                        </StyledRange>
                    )
                }

                {
                    type !== InterventionType.POSE && (
                        <p>{date ? formatDate(date) : '--/--/----'}</p>
                    )
                }
                <IonChip outline color="primary">{reference}</IonChip>
                <IonChip color={chipColor}>{label}</IonChip>
                <InterventionStatusChip status={status} />
            </IonLabel>
        </IonItem>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');
const formatPoseDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toLocaleString(DateTime.DATE_MED);

const StyledRange = styled.p`
    display: flex;
    align-items: center;

    span:first-of-type {
        margin-right: 2px;
    }

    span:last-of-type {
        margin-left: 2px;
    }
`;

function ArchivedInterventionItem({ intervention }: InterventionItemProps) {
    // This will hold a function that undoes the mutation
    const undoMutationRef = useRef<() => void>();

    const { mutateAsync, isLoading: deleting, isSuccess: deleted } = useMutation(() => {
        // A mutation function must return a Promise
        const mutationPromise = new Promise((resolve, reject) => {
            // Run the mutation after a set amount of time
            const mutateTimeout = setTimeout(() => {
                deleteIntervention(intervention.id)
                    .then((result) => resolve(''))
                    .catch((err) => reject(err));
            }, 5000);

            // Prepare the function that cancels the mutation
            const cancelMutation = () => {
                clearTimeout(mutateTimeout);
                reject({ message: "mutationCancelled" });
            };

            // Put the function that cancels the mutation to a ref to be used by the cancel button
            undoMutationRef.current = cancelMutation;
        });

        return mutationPromise;
    });

    // Si suppression effective
    if (deleted) {
        return <></>;
    }

    return (
        <>
            {
                !deleting && (
                    <IonItemSliding>
                        <IonItemOptions side="start">
                            <IonItemOption color="danger" onClick={() => mutateAsync()}>
                                Supprimer
                            </IonItemOption>
                        </IonItemOptions>

                        <InterventionItem intervention={intervention} />
                    </IonItemSliding>
                )
            }

            <IonToast
                isOpen={deleting}
                message={`${intervention.reference} suppression programmée`}
                buttons={[
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        handler: () => {
                            undoMutationRef.current && undoMutationRef.current();
                        },
                    },
                ]}
            />
        </>
    )
}

interface FormModel {
    type?: InterventionType | string;
    status?: AllInterventionStatus | string;
    customerName?: string;
    dateStart?: string; // iso8601
    dateEnd?: string; // iso8601
    startCreateDate?: string; // iso8601
    endCreateDate?: string; // iso8601
}

function FilterModalContent({ onClose }: { onClose(): void }) {
    const { filter, setFilter } = useInterventionsFilterStore();

    const methods = useForm<FormModel>({
        defaultValues: filter,
    });

    const onSubmit = (data: FormModel) => {
        const sanitized = omitBy(data, v => isNil(v) || isEmpty(v));
        setFilter(sanitized);
        onClose();
    };

    const resetFilter = () => {
        methods.reset();
        setFilter({});
        onClose();
    }

    return (
        <FormProvider {...methods}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Filtres</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>Fermer</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonList>
                    <GenericSelect
                        name={"type"}
                        label={"Type intervention"}
                        options={
                            Object.keys(InterventionType)
                                .map(
                                    type => ({
                                        label: interventionUI.typeTranslation[type as InterventionType],
                                        value: type,
                                    })
                                )
                        }
                    />

                    <GenericSelect
                        name={"status"}
                        label={"Statut"}
                        options={
                            Object.keys(AllInterventionStatus)
                                .map(
                                    type => ({
                                        label: interventionUI.statusTranslation[type as AllInterventionStatus],
                                        value: type,
                                    })
                                )
                        }
                    />

                    <GenericTextField name={"customerName"} label={"Nom client"} />

                    <GenericDatetimePicker name={"dateStart"} label={"Date début"} />

                    <GenericDatetimePicker name={"dateEnd"} label={"Date fin"} />
                </IonList>
            </IonContent>

            <IonFooter>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton fill={"clear"} color={"primary"} expand="block" onClick={resetFilter}>
                                Tout effacer
                            </IonButton>
                        </IonCol>

                        <IonCol>
                            <IonButton fill={"solid"} color={"primary"} expand="block" onClick={methods.handleSubmit(onSubmit)}>
                                <IonIcon slot={"start"} md={checkmarkCircleSharp} ios={shieldCheckmarkOutline} />
                                Appliquer
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </FormProvider>
    )
}
