import create from "zustand";

interface StoreModel {
    isOpen: boolean;

    data?: string;

    showModal(): void;
    closeModal(): void;
    closeModalAndSetData(data: string): void;
    resetData(): void;
}

export const useSketchingPadModalStore = create<StoreModel>((set) => ({
    isOpen: false,
    showModal: () => set({ isOpen: true }),
    closeModal: () => set({ isOpen: false }),
    closeModalAndSetData: (data: string) => set({ data, isOpen: false }),
    resetData: () => set({ data: undefined }),
}));
