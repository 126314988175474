import {AllInterventionStatus} from "../../models/intervention.model";
import {IonBadge, IonSpinner} from "@ionic/react";
import {Color} from "@ionic/core";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {useUser} from "../Auth/auth.store";
import {
    countInterventionsByStatus,
    CountInterventionsByStatus
} from "../../calls/Interventions/countInterventionsByStatus";
import {pick, sum} from "lodash";

interface InterventionStatusCountBadgeProps {
    status: Array<AllInterventionStatus | string> | "all";
    color?: Color;
}

export default function InterventionStatusCountBadge({ status, color }: InterventionStatusCountBadgeProps) {
    const { isLoading, data } = useCountInterventionsByStatus();

    if (isLoading) {
        return (<IonSpinner name="lines-sharp-small" />);
    }

    let count;

    if (Array.isArray(status) && data) {
        count = sum(Object.values(pick(data, status)));
    } else {
        count = data ? data[status as string] : 0;
    }

    return (
        <IonBadge slot="end" color={color || 'light'}>
            {count}
        </IonBadge>
    )
}

function useCountInterventionsByStatus() {
    const user = useUser();

    const payload: CountInterventionsByStatus = {
        technicianId: user.id,
    };

    return useQuery(['countByStatus', payload], () => countInterventionsByStatus(payload));
}
