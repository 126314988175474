import {
    IonAccordion,
    IonAccordionGroup,
    IonBackButton,
    IonButtons, IonCol,
    IonContent, IonGrid,
    IonHeader, IonItem, IonLabel, IonList,
    IonPage,
    IonProgressBar, IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useQuery} from "@tanstack/react-query";
import {listInstallationProcedures} from "../calls/Interventions/listInstallationProcedures";
import {ProductTypology} from "../models/intervention.model";

// Core viewer
import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the localization file
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useAuthStore} from "../components/Auth/auth.store";
import {useActiveCompanyId} from "../components/Company/company.store";
import {INTERVENTION_BASE_URL} from "../http";
import {useState} from "react";
import {useLocation, useParams} from "react-router";

export default function InstallationProcedures() {
    const { search  } = useLocation();
    const query = new URLSearchParams(search);

    const selectedTypology = query.get('selectedTypology');

    const { isLoading, isError, data } = useListInstallationProcedures();

    const typologyToLabel = useGetProductTypologyLabel();

    const procedures = Object.entries(data || {}).map(
        ([key, value]) => {
            return {
                label: typologyToLabel(key as ProductTypology),
                typology: key,
                files: value,
            }
        }
    ).sort((a, b) => a.typology === selectedTypology ? -1 : 1);

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const getPdfViewerProps = useGetPdfViewerProps();

    const [selectedFile, setSelectedFile] = useState('');

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>Procédures d'installation</IonTitle>
                    {
                        isLoading && <IonProgressBar type="indeterminate" />
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonGrid>
                    <IonRow>
                        <IonCol size="6">
                            <IonAccordionGroup value={selectedTypology}>
                                {
                                    procedures.map(p => {
                                        return (
                                            <IonAccordion key={p.typology} value={p.typology}>
                                                <IonItem slot="header" color="light">
                                                    <IonLabel>{p.label}</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    <IonList inset={true}>
                                                        {
                                                            p.files.map(
                                                                f => {
                                                                    return (
                                                                        <IonItem key={f.name}
                                                                                 button
                                                                                 detail={true}
                                                                                 class="ion-text-wrap"
                                                                                 onClick={() => setSelectedFile(f.name)}
                                                                        >
                                                                            <IonLabel>{f.name}</IonLabel>
                                                                        </IonItem>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </IonList>
                                                </div>
                                            </IonAccordion>
                                        )
                                    })
                                }
                            </IonAccordionGroup>
                        </IonCol>
                        <IonCol size="6">
                            {
                                selectedFile && (
                                    <Worker workerUrl={'/pdf.worker.min.js'}>
                                        <Viewer
                                            {...getPdfViewerProps(selectedFile)}
                                            plugins={[
                                                // Register plugins
                                                defaultLayoutPluginInstance,
                                            ]}
                                            localization={fr_FR as unknown as LocalizationMap}
                                        />
                                    </Worker>
                                )
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

function useListInstallationProcedures() {
    return useQuery(['installationProcedures'], listInstallationProcedures, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });
}

function useGetProductTypologyLabel() {
    return (typology: ProductTypology) => {
        const label = {
            [ProductTypology.PAC_AIR_AIR]: 'Pompe à chaleur Air Air',
            [ProductTypology.PAC_AIR_EAU]: 'Pompe à chaleur Air Eau',
            [ProductTypology.PHOTOVOLTAIC]: 'Photovoltaïque',
            [ProductTypology.BALLON_THERMO]: 'Ballon thermodynamique',
        }[typology];

        return label || 'N/A';
    }
}

function useGetPdfViewerProps() {
    const { login, token } = useAuthStore(e => ({
        token: e.token || '',
        login: e.user?.login || ''
    }));

    const entity = useActiveCompanyId();

    return (name: string): {
        fileUrl: string | Uint8Array;
        // Additional authentication headers
        httpHeaders?: Record<string, string | string[]>;
    } => {
        return {
            fileUrl: `${INTERVENTION_BASE_URL}/installation-procedures/${name}`,
            httpHeaders: {
                'x-authorization-token': `${login}:${token}`,
                'x-entity': entity,
            }
        }
    };
}
