import {IonButton, IonCol, IonFooter, IonIcon, IonModal, IonPage, IonRow} from "@ionic/react";
import {useSketchingPadModalStore} from "./sketching-pad-modal.store";
import {BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "@emotion/styled";
import {checkmarkCircleOutline, checkmarkCircleSharp} from "ionicons/icons";

export default function SketchingPadModal() {
    const { isOpen, closeModal } = useSketchingPadModalStore();

    return (
        <IonModal isOpen={isOpen} onWillDismiss={closeModal} style={{
            '--backdrop-opacity': .4,
            '--width': '85%',
            '--height': '90%',
        }} keepContentsMounted={false}>
            <ModalContent />
        </IonModal>
    )
}

function useSketchingCanvas() {
    const [isDrawing, setIsDrawing] = useState(false);

    const [[preMouseX, prevMouseY], setPreMousePosition] = useState<[number, number]>([0, 0]);

    const [selectedTool, setSelectedTool] = useState<"brush" | "eraser" | "rectangle" | "circle">("brush");

    const [brushWidth, setBrushWidth] = useState(2);

    const [selectedColor, setSelectedColor] = useState("#000000");

    const isCustomColor = useMemo(() => {
        return !['#000000', '#E02020', '#FFDF2AFF', '#6DD400', '#0023D4'].includes(selectedColor);
    }, [selectedColor]);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    // on load
    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        const canvas = canvasRef.current;

        // setting canvas width/height.. offsetwidth/height returns viewable width/height of an element
        canvas!.width = canvas!.offsetWidth;
        canvas!.height = canvas!.offsetHeight - 54; // On enlève 54px = height du footer
    }, [canvasRef]);

    const startDraw = useCallback(() => {
        setIsDrawing(true);

        // passing current mouseX position as prevMouseX value
        // passing current mouseY position as prevMouseY value
        // setPreMousePosition([e.offsetX, e.offsetY]);

        const ctx = canvasRef.current!.getContext('2d');
        ctx!.beginPath();
        ctx!.lineWidth = brushWidth; // passing brushSize as line width
        ctx!.strokeStyle = selectedColor; // passing selectedColor as stroke style
        ctx!.fillStyle = selectedColor; // passing selectedColor as fill style
    }, [canvasRef, brushWidth, selectedColor]);

    const drawing = useCallback((e: MouseEvent | TouchEvent) => {
        // if isDrawing is false return from here
        if (!isDrawing) {
            return;
        }

        const canvas = canvasRef.current;
        const ctx = canvas!.getContext('2d');

        // ctx!.putImageData(snapshot, 0, 0); // adding copied canvas data on to this canvas

        if(selectedTool === "brush" || selectedTool === "eraser") {
            // if selected tool is eraser then set strokeStyle to white
            // to paint white color on to the existing canvas content else set the stroke color to selected color
            ctx!.strokeStyle = selectedTool === "eraser" ? "#fff" : selectedColor;

            if (e instanceof MouseEvent) {
                ctx!.lineTo(e.offsetX, e.offsetY); // creating line according to the mouse pointer
            }

            if (e instanceof TouchEvent) {
                let touch = e.touches[0];
                const rect = (e.target as any).getBoundingClientRect();
                const x = touch.pageX - rect.left;
                const y = touch.pageY - rect.top;
                ctx!.lineTo(x, y); // creating line according to the touch position
            }

            ctx!.stroke(); // drawing/filling line with color
        } else if(selectedTool === "rectangle") {
            // drawRect(e);
        } else if(selectedTool === "circle") {
            // drawCircle(e);
        } else {
            // drawTriangle(e);
        }
    }, [canvasRef, isDrawing, selectedColor]);

    const stopDraw = useCallback(() => setIsDrawing(false), []);

    const clearCanvas = useCallback(() => {
        const canvas = canvasRef.current;
        const ctx = canvas!.getContext('2d');
        ctx!.clearRect(0, 0, canvas!.width, canvas!.height); // clearing whole canvas
    }, [canvasRef]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        const canvas = canvasRef.current;

        // window.addEventListener("load", onWindowLoad);

        canvas.addEventListener("mousedown", startDraw);
        canvas.addEventListener ("mousemove", drawing);
        canvas.addEventListener ("mouseup", stopDraw);

        canvas.addEventListener("touchstart", startDraw);
        canvas.addEventListener("touchend", stopDraw);
        canvas.addEventListener("touchmove", drawing);

        return () => {
            // window.removeEventListener("load", onWindowLoad);

            canvas.removeEventListener("mousedown", startDraw);
            canvas.removeEventListener("mousemove", drawing);
            canvas.removeEventListener("mouseup", stopDraw);

            canvas.removeEventListener("touchstart", startDraw);
            canvas.removeEventListener("touchend", stopDraw);
            canvas.removeEventListener("touchmove", drawing);
        }
    }, [canvasRef, startDraw, drawing, stopDraw]);

    return {
        canvasRef,
        selectedTool,
        setSelectedTool,
        brushWidth,
        setBrushWidth,
        selectedColor,
        setSelectedColor,
        isCustomColor,
        clearCanvas,
    }
}

function ModalContent() {
    const { closeModal, closeModalAndSetData } = useSketchingPadModalStore();

    const {
        canvasRef,
        selectedTool,
        setSelectedTool,
        brushWidth,
        setBrushWidth,
        selectedColor,
        setSelectedColor,
        isCustomColor,
        clearCanvas
    } = useSketchingCanvas();

    const handleSubmitSignature = () => {
        const data = canvasRef.current?.toDataURL("image/png"); // save image as PNG

        // ne rien faire si pas de données
        if (!data) {
            return;
        }

        closeModalAndSetData(data);
    }

    return (
        <IonPage>
            <PadContainer>
                <SketchToolBar id={"SketchToolBar"}>
                    <ul className="options">
                        <li className="option">
                            <input
                                type="range"
                                id="size-slider"
                                min="1"
                                max="30"
                                value={brushWidth}
                                onChange={e => {
                                    setBrushWidth(+e.target.value)
                                }}
                            />
                        </li>

                        <li className={`option tool ${selectedTool === 'brush' ? 'active' : ''}`} id="brush" onClick={() => setSelectedTool('brush')}>
                            <img src="/assets/sketch/icons/brush.svg" alt="" />
                            <span>Dessiner</span>
                        </li>

                        <li className={`option tool ${selectedTool === 'eraser' ? 'active' : ''}`} id="eraser" onClick={() => setSelectedTool("eraser")}>
                            <img src="/assets/sketch/icons/eraser.svg" alt="" />
                            <span>Gomme</span>
                        </li>
                    </ul>

                    <ul className="colors options">
                        <li className={`option ${selectedColor === "#000000" ? "selected" : ""}`} onClick={() => setSelectedColor("#000000")}></li>
                        <li className={`option ${selectedColor === "#E02020" ? "selected" : ""}`} onClick={() => setSelectedColor("#E02020")}></li>
                        <li className={`option ${selectedColor === "#FFDF2AFF" ? "selected" : ""}`} onClick={() => setSelectedColor("#FFDF2AFF")}></li>
                        <li className={`option ${selectedColor === "#6DD400" ? "selected" : ""}`} onClick={() => setSelectedColor("#6DD400")}></li>
                        <li className={`option ${selectedColor === "#0023D4" ? "selected" : ""}`} onClick={() => setSelectedColor("#0023D4")}></li>
                        <li className={`option ${isCustomColor ? "selected" : ""}`} style={{
                            backgroundColor: isCustomColor ? selectedColor : '#fff',
                            border: isCustomColor ? 'none' : '1px solid #bfbfbf',
                        }}>
                            <input
                                type="color"
                                id="color-picker"
                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value);
                                }}
                                onInput={(e: BaseSyntheticEvent) => {
                                    setSelectedColor(e.target.value);
                                }}
                            />
                        </li>
                    </ul>

                    <ul className="options">
                        <li className={`option tool`} id="clear" onClick={() => clearCanvas()}>
                            <span>Effacer tout</span>
                        </li>
                    </ul>
                </SketchToolBar>

                <canvas
                    style={{
                        background: 'white',
                        border: '1px solid #F0F0F0',
                        borderRadius: '5px',
                        flexGrow: 1,
                    }}
                    ref={canvasRef}
                />

                <IonFooter>
                    <IonRow>
                        <IonCol>
                            <IonButton expand={'block'} fill={'clear'} onClick={() => closeModal()}>Fermer</IonButton>
                        </IonCol>

                        <IonCol>
                            <IonButton expand={'block'} onClick={handleSubmitSignature}>
                                <IonIcon slot={"start"} md={checkmarkCircleSharp} ios={checkmarkCircleOutline} />
                                Valider
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonFooter>
            </PadContainer>
        </IonPage>
    )
}

const PadContainer = styled('div')`
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 5px;
    display: flex;
    flex-direction: column;

    .signatureCanvas {
        background: white;
        border: 1px solid #F0F0F0;
        border-radius: 5px;
    }
`

const SketchToolBar = styled('div')`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    width: 100%;

    .options {
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .options .option {
        display: flex;
        cursor: pointer;
        align-items: center;
        margin: 5px;
    }

    .options .option :where(span, label) {
        color: #5A6168;
        cursor: pointer;
        padding-left: 10px;
    }

    .options .option:is(:hover, .active) img {
        filter: invert(17%) sepia(90%) saturate(3000%) hue-rotate(900deg) brightness(100%) contrast(100%);
    }

    .options .option:is(:hover, .active) :where(span, label) {
        color: #4A98F7;
    }

    .colors .options {
        display: flex;
        justify-content: space-between;
    }

    .colors .option {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-top: 3px;
        position: relative;
    }

    //.colors .option:nth-child(1) {
    //    background-color: #fff;
    //    border: 1px solid #bfbfbf;
    //}

    .colors .option:nth-of-type(1) {
        background-color: #000;
    }

    .colors .option:nth-of-type(2) {
        background-color: #E02020;
    }

    .colors .option:nth-of-type(3) {
        background-color: #ffdf2a;
    }

    .colors .option:nth-of-type(4) {
        background-color: #6DD400;
    }

    .colors .option:nth-of-type(5) {
       background-color: #0023D4;
    }

    .colors .option.selected::before{
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        height: 12px;
        width: 12px;
        background: inherit;
        border-radius: inherit;
        border: 2px solid #fff;
        transform: translate(-50%, -50%);
    }

    .option #color-picker{
        opacity: 0;
        cursor: pointer;
    }
`;
