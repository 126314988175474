import {Controller} from "react-hook-form";
import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React from "react";
import {TextFieldTypes} from "@ionic/core";

interface GenericTextFieldProps {
    label: string;
    labelPosition?: 'fixed' | 'stacked' | 'floating';
    name: string;
    type?: TextFieldTypes;
}

export default function GenericTextField({ label, labelPosition, name, type }: GenericTextFieldProps) {
    return (
        <Controller
            name={name}
            render={
                ({field}) => {
                    return (
                        <IonItem>
                            <IonLabel position={labelPosition}>{label}</IonLabel>
                            <IonInput
                                value={field.value}
                                onIonChange={field.onChange}
                                type={type}
                                placeholder={label}
                            />
                        </IonItem>
                    )
                }
            }
        />
    )
}
