import {useRef, useState} from "react";

export default function useLongPress() {
    const [action, setAction] = useState<'longpress' | 'click'>();

    const timerRef = useRef<NodeJS.Timeout | null>();
    const isLongPress = useRef<boolean>();

    function startPressTimer() {
        isLongPress.current = false;
        timerRef.current = setTimeout(() => {
            isLongPress.current = true;
            setAction('longpress');
        }, 400)
    }

    function handleOnClick(e: any) {
        console.log('handleOnClick');
        if ( isLongPress.current ) {
            console.log('Is long press - not continuing.');
            return;
        }
        setAction('click')
    }

    function handleOnMouseDown() {
        console.log('handleOnMouseDown');
        startPressTimer();
    }

    function handleOnMouseUp() {
        console.log('handleOnMouseUp');
        clearGestureTimeout();
    }

    function handleOnTouchStart() {
        console.log('handleOnTouchStart');
        startPressTimer();
    }

    function handleOnTouchEnd() {
        if ( action === 'longpress' ) return;
        console.log('handleOnTouchEnd');
        clearGestureTimeout();
    }

    function clearGestureTimeout() {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }

    return {
        action,
        handlers: {
            onClick: handleOnClick,
            onMouseDown: handleOnMouseDown,
            onMouseUp: handleOnMouseUp,
            onTouchStart: handleOnTouchStart,
            onTouchEnd: handleOnTouchEnd
        }
    }
}
