import React, {useEffect, useMemo, useState} from 'react';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter'
import * as blobUtil from 'blob-util';
import L, {LatLngExpression, Map as LeafletMap} from 'leaflet';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonViewDidEnter
} from "@ionic/react";
import {Geolocation} from '@capacitor/geolocation';
import styled from "@emotion/styled";
import {cameraOutline, cameraSharp, closeOutline, closeSharp} from "ionicons/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useUpdateInterventionMutation} from "../Interventions/useUpdateInterventionMutation";
import {InterventionType} from "../../models/intervention.model";
import {FileToUploadDto, interventionAttachFiles} from "../../calls/Interventions/attachInterventionFiles";

interface LocalizeMeProps {
    interventionId: string;
    interventionReference: string;
    position?: LatLngExpression;
    onDismiss(base64: string | null, role: 'confirm' | 'cancel'): void;
}

const defaultPosition: LatLngExpression = [48.85341, 2.3488]; // Coordonnées France centrées sur Paris

function LocalizeMe({ interventionId, interventionReference, position = defaultPosition, onDismiss }: LocalizeMeProps) {
    const markerIcon = useMemo(() => {
        return new L.Icon({
            iconUrl: '/marker-icon-red.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        })
    }, []);

    const [marker, setMarker] = useState(position);
    const [map, setMap] = useState<LeafletMap | null>(null);
    const [screenShoter, setScreenShoter] = useState<SimpleMapScreenshoter | null>(null);

    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });

    useEffect(() => {
        if (!map || screenShoter) {
            return;
        }

        window.dispatchEvent(new Event("resize"));
        setTimeout(function () {
            (map as any).invalidateSize();
        },1);

        setScreenShoter(
            new SimpleMapScreenshoter({
                hidden: true
            }).addTo(map as any)
        )
    }, [map, screenShoter]);

    const { data: currentPosition, isLoading, isError } = useQuery([interventionId, 'location'], () => Geolocation.getCurrentPosition(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    useEffect(() => {
        if (isLoading || isError) {
            return;
        }

        // const latLng: LatLngExpression = [44.25862560314637, 2.2666291507262537,];

        const latLng: LatLngExpression = [
            currentPosition.coords.latitude || 0,
            currentPosition.coords.longitude || 0
        ];

        setMarker(latLng);

        if (!map) {
            return;
        }

        map.flyTo(latLng, 18);
    }, [currentPosition, isLoading, isError, map]);

    const [presentAlert] = useIonAlert();
    const { mutateAsync: attachFiles } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(interventionId, dto));
    const { mutateAsync: updateIntervention } = useUpdateInterventionMutation(InterventionType.VISITE_TECHNIQUE);
    const { mutateAsync: takeScreen, isLoading: processing } = useMutation(async () => {
        try {
            const blob: any = await screenShoter?.takeScreen('blob', {
                // caption: function () {
                //     return ''
                // }
            });

            const base64Content = await blobUtil.blobToBase64String(blob);

            const id = Date.now();

            const dto: FileToUploadDto = {
                base64Content,
                filename: `Position GPS Chantier ${interventionReference} ${id}.png`,
                tag: 'Position GPS Chantier',
                description: `Position GPS Chantier ${interventionReference}`,
                contentType: 'image/png',
            }

            await attachFiles([dto]);

            await updateIntervention({
                id: interventionId,
                dto: {
                    exactPosition: {
                        coords: (marker as [number, number]).reverse() as [number, number], // re format au format geojson
                    }
                }
            })

            onDismiss(base64Content, 'confirm');
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onDismiss(null, 'cancel')}>
                            <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                            Fermer
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Coordonnées GPS chantier</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {
                    isLoading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            height: '100%',
                            flexDirection: "column"
                        }}>
                            <IonSpinner name="lines" />
                            <p>Veuillez patienter géolocalisation en cours</p>
                        </div>
                    ) : (
                        <>
                            {
                                isError && (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        height: '100%',
                                        flexDirection: "column"
                                    }}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style={{
                                                fill: 'var(--ion-color-danger)',
                                            }}>
                                                <path d="M0 0h24v24H0zm11.75 11.47l-.11-.11z" fill="none"/>
                                                <path d="M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z"/>
                                            </svg>
                                        </div>

                                        <IonText color={"danger"}>
                                            <p>Veuillez autoriser l'application à géolocaliser votre appareil</p>
                                        </IonText>
                                    </div>
                                )
                            }

                            {
                                (!isLoading && !isError) && (
                                    <StyledMapContainer ref={setMap} center={defaultPosition} zoom={7} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='&copy'
                                            url={"https://wxs.ign.fr/essentiels/geoportail/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}"}
                                        />

                                        <Marker
                                            position={marker}
                                            icon={markerIcon}
                                        />
                                    </StyledMapContainer>
                                )
                            }
                        </>
                    )
                }
            </IonContent>

            <IonFooter>
                <IonButton
                    disabled={isLoading || isError}
                    expand={"block"}
                    onClick={() => {
                        presentAlert({
                            header: 'Confirmer la position',
                            message: 'Êtes-vous sûr de vouloir valider cette position ?',
                            buttons: [
                                {
                                    text: 'Annuler',
                                    role: 'cancel',
                                },
                                {
                                    text: 'Confirmer',
                                    role: 'confirm',
                                },
                            ],
                            onDidDismiss: async (e: CustomEvent) => {
                                const isConfirmed = e.detail.role === 'confirm';

                                if (!isConfirmed) {
                                    return;
                                }

                                // On lance tout après confirmation
                                await takeScreen();
                            }
                        })
                    }}>
                    <IonIcon slot={'start'} md={cameraSharp} ios={cameraOutline} />
                    Capturer
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default LocalizeMe;

const StyledMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;
`;
