import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import axios from "axios";

interface LoginPayload {
    login: string;
    password: string;
    entity?: string;
}

interface LoginResponse {
    success: {
        code: number;
        token: string;
        entity: string;
        message: string;
    };
}

export function login(payload: LoginPayload) {
    const url = `${http.defaults.baseURL}${Endpoint.userLogin}`;
    return axios.post<LoginResponse>(url, payload);
}
