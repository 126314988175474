import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import {base64FromPath} from "@capacitor-community/filesystem-react";

export interface UserPhoto {
    filename: string;
    base64Content: string;
    webviewPath?: string;
    contentType: string;
}

interface UsePhotoGalleryProps {
    onCaptured?(photos: UserPhoto[]): void
}

interface TakePhotoOptions {
    source?: CameraSource,
}
export function usePhotoGallery({ onCaptured }: UsePhotoGalleryProps = {}) {
    const [photos, setPhotos] = useState<UserPhoto[]>([]);

    const takePhoto = async ({ source = CameraSource.Camera }: TakePhotoOptions = {}) => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source,
            quality: 100,
        });

        const base64Content = await base64FromPath(photo.webPath!);

        const filename = new Date().getTime() + `.${photo.format}`;

        const newPhotos = [
            {
                filename,
                base64Content,
                webviewPath: photo.webPath,
                contentType: `image/${photo.format}`,
            },
            ...photos,
        ];

        onCaptured && onCaptured(newPhotos);

        setPhotos(newPhotos);
    };

    return {
        photos,
        takePhoto,
    };
}
